import React from "react";
import { Announce } from "assets/icons";

function HeroBanner({ scrollToAreas }) {
  return (
    <div className="holder">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 content">
            <span className="tag">Discover Local Shops & Businesses</span>
            <div className="textWrap">
              <h1>
                Fatafat <span>helps you</span> <br /> find local shops{" "}
                <span>and businesses</span>
                <br /> in your area{" "}
                <span>and pass your order to them through WhatsApp</span>
              </h1>
              <div className="urduWrapper">
                <p lang="ur" dir="rtl">
                  فٹا فٹ آپ کو اپنے علاقے میں مقامی دکانیں اور کاروبار تلاش کرنے
                  اور Whatsapp کے ذریعے آپکا آرڈر ان تک پہنچانے میں مدد کرتا ہے۔
                </p>
                <img src={Announce} alt="" />
              </div>
            </div>

            <a onClick={scrollToAreas} className="heroButton">
              Explore Your Area
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeroBanner;

