import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import { api } from "api";
import { errors, errorsCodes, globals } from "common";
import { MAP_BOX_CLIENT_ID } from "../../config";
import "../map/_map.scss";
import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.accessToken = MAP_BOX_CLIENT_ID;

function Map(
  {
    shopArea,
    pin,
    setPin,
    draggable = false,
    setAddress,
    setShowAlert,
    setErrorMsg,
  }
) {
  const mapContainer = useRef(null);
  const map = useRef(null);
  const marker = useRef(null);
  const {token} = useParams();
  let coordinates = [pin?.longitude, pin?.latitude];

  const resetAddress = () => {
    setAddress(globals.DEFAULT_ADDRESS);
  }

  async function onDragEnd() {
    setShowAlert(false);
    const lngLat = marker.current.getLngLat();
    coordinates = [lngLat?.lng, lngLat?.lat];
    setPin({
      longitude: lngLat?.lng,
      latitude: lngLat?.lat,
    });
    marker.current.setLngLat(coordinates);
    try {
      const addressDetail = await api.getAddress({
        token,
        params: {longitude: lngLat?.lng, latitude: lngLat?.lat},
      });
      const plot = addressDetail.plot;
      const area = addressDetail.area;
      let error;
      if (area.id !== shopArea?.id) {
        error = errors.ADDRESS_OUT_OF_RANGE(shopArea?.area);
      }
      else if (!plot?.point) {
        error = errors.ADDRESS_NOT_FOUND;
      }
      if (error) {
        setErrorMsg(error);
        setShowAlert(true);
        resetAddress();
        return;
      }
      setAddress({
        plot: plot?.plot_number,
        block: plot?.block?.name,
        sector: plot?.block?.sector,
        street: plot?.street,
      });
    } catch (error) {
      if (error?.response?.status === errorsCodes.NOT_FOUND_CODE) {
        setErrorMsg(errors.AREA_OUT_OF_RANGE);
        resetAddress();
        setShowAlert(true);
      }
    }
  }

  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v12",
        center: coordinates,
        zoom: 14,
      });
    }

    if (!marker.current) {
      marker.current = new mapboxgl.Marker({
        color: "red",
        draggable: draggable,
      })
        .setLngLat(coordinates)
        .addTo(map.current);
    } else {
      !draggable && marker.current.setLngLat(coordinates);
    }
    draggable && marker.current.on("dragend", onDragEnd);
  }, []);

  useEffect(() => {
    const handleClick = (e) => {
      window.open(`https://www.google.com/maps?q=${pin?.latitude},${pin?.longitude}`, '_blank');
    };

    if (map?.current) {
      map.current?.on('click', handleClick);
    }

    return () => {
      if (map?.current) {
        map.current?.off('click', handleClick);
      }
    };
  }, [pin]);

  return (
    <div>
      <div ref={mapContainer} className="map-container"/>
    </div>
  );
}

export default Map;

