import React, { useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { BsFillGrid3X3GapFill, BsList, BsCheck } from "react-icons/bs";
import { sortBy } from "common/constants";
import { toast } from "react-toastify";
import { errors, labels, local } from "common";
import { useSelector } from "react-redux";

function SortingDropDown({
  selectedValue,
  setSelectedValue,
  setSortByDistance,
  setPin,
  gridView,
  setGridView,
  setIsLoading,
  shopsLength,
  sortParam,
  setSearchParams,
}) {
  const { permission } = useSelector((state)=> state.general)
  
  const handleSelect = (eventKey, event) => {
    if (eventKey == labels.SORT_ALPHABETICALLY && selectedValue!==labels.SORT_ALPHABETICALLY) {
      setSortByDistance(false);
      setSelectedValue(labels.SORT_ALPHABETICALLY);
      setSearchParams(searchParams => {
        searchParams.set("sort", "name");
        return searchParams;
      });
    } else if (eventKey == labels.NEAREST_SHOPS && local.isLocationAllowed() && selectedValue!==labels.NEAREST_SHOPS) {
      setSortByDistance(true);
      setSelectedValue(labels.NEAREST_SHOPS);
      setSearchParams(searchParams => {
        searchParams.set("sort", "distance");
        return searchParams;
      });
    } else if (eventKey == labels.NEAREST_SHOPS && !local.isLocationAllowed()) {
      toast.error(errors.ACCESS_REQUIRED);
    }
  };

  const handleGridToggle = (isGridView) => {
    if (gridView !== isGridView) {
      setGridView(isGridView);
      setSearchParams(searchParams => {
        searchParams.set("grid", isGridView.toString());
        return searchParams;
      });
    }
  }  

  useEffect(() => {
    if(permission || local.isLocationAllowed()) {
      setIsLoading(true);
      navigator.geolocation.getCurrentPosition(
        function (position) {
          if (!local.isLocationAllowed() || sortParam!=='name') {
            setSelectedValue(labels.NEAREST_SHOPS);
          }
          setPin({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        local.setIsLocationAllowed(true);
      });
      setIsLoading(false);
    }
  }, [permission]);

  return (
    shopsLength > 0 && <div className="container">
      <div className="sorting">
        <div>
          <Dropdown onSelect={handleSelect}>
            <Dropdown.Toggle className="sortingDropdown" id="dropdown-basic">
              <div>
                <strong>Sort:</strong> {selectedValue}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {sortBy.map((sortValue) => (
                <Dropdown.Item key={sortValue} eventKey={sortValue}>
                  {selectedValue === sortValue && <BsCheck />}
                  {sortValue}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="toggleSwitch">
          <a
            onClick={()=>{handleGridToggle(false)}}
            className={!gridView && "active"}
          >
            <BsList />
          </a>
          <a onClick={()=>{handleGridToggle(true)}} className={gridView && "active"}>
            <BsFillGrid3X3GapFill />
          </a>
        </div>
      </div>
    </div>
  );
}

export default SortingDropDown;

