import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'api';

const generalExtraActions = {
  getAreas: createAsyncThunk('general/getAreas', api.getAreas),
  getCategories: createAsyncThunk('general/getCategories', api.getCategories),
  getSearchCategories: createAsyncThunk('general/getSearchCategories', api.getCategories),
  getShops: createAsyncThunk('general/getShops', api.getShops),
  getShopsFromStart: createAsyncThunk('general/getShopsFromStart', api.getShopsFromStart),
  getOrderShops: createAsyncThunk('general/getOrderShops', api.getOrderShops),
  createCustomerOrder: createAsyncThunk('general/createCustomerOrder', api.createCustomerOrder),
  getCustomerReviews: createAsyncThunk('general/getCustomerReviews', api.getCustomerReviews),
  createCustomerReview: createAsyncThunk('general/createCustomerReview', api.createCustomerReview),
  getShopDetail: createAsyncThunk('general/getShopDetail', api.getShopDetail),
  getSectors: createAsyncThunk('general/getSectors', api.getSectors),
  getBlocks: createAsyncThunk('general/getBlocks', api.getBlocks),
};

export default generalExtraActions;
