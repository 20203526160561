import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal } from "react-bootstrap";
import { labels, local, schemas } from "common";
import { utils } from "../../common/";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { authExtraActions } from "reduxStore/actions";
import 'modals/otpModal/_otpModal.scss';
import { LoaderBtn } from "../../components";

function OtpModal({show, setShow, onSubmit, loading, countDown, setCountDown}) {
  const dispatch = useDispatch();
  const [showResendOTP, setShowResendOTP] = useState(false);
  const [restart, setRestart] = useState(false);
  const timerId = useRef();

  useEffect(() => {
    if (show) {
      timerId.current = setInterval(() => {
        setCountDown(prev => prev - 1);
      }, 1000);
    }
    if (restart) {
      setCountDown(60);
      setRestart(false);
    }
    return () => clearInterval(timerId.current);
  }, [show, restart])

  useEffect(() => {
    if (countDown <= 0) {
      clearInterval(timerId.current);
      setShowResendOTP(true);
    }
  }, [countDown, setShowResendOTP])


  const handleResendOTP = (resetForm) => {
    dispatch(authExtraActions.sendOtp({phone_number: local.getUser().phoneNumber}));
    setShowResendOTP(false);
    resetForm();
    setRestart(true);
  };

  return (
    <Modal className='loginModal' show={show} fullscreen="md-down" onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        {/* Modal Header */}
        <div className='smallTitle'><span>Enter OTP</span></div>
        <Modal.Title>Verify Your WhatsApp</Modal.Title>
        <p>Enter the OTP that you received from WhatsApp to activate your session.</p>
      </Modal.Header>

      {/* Modal Body */}

      <Formik
        validationSchema={schemas.otpVerify}
        onSubmit={onSubmit}
        initialValues={{
          otp: '',
        }}
      >
        {({handleSubmit, handleChange, values, touched, errors, resetForm}) => (
          <Form className='formFields' id="form" onSubmit={handleSubmit} noValidate>
            <Modal.Body>
              <div className='formFields'>
                <Form.Label for="otp">Enter OTP<span style={{color: "red"}}> *</span>
                  {!showResendOTP ?
                    <span className='otpTimer'>(Expires in: {utils.formatCounter(countDown)})</span> :
                    <span className="expired">(OTP Expired)</span>
                  }
                </Form.Label>
                <div>
                  <Form.Control placeholder="------" type="tel" id="otp" name="otp"
                                value={values.otp} isInvalid={!!errors.otp} onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.otp}
                  </Form.Control.Feedback>

                  {showResendOTP && <a className='resend' onClick={() => {
                    handleResendOTP(resetForm)
                  }}>Resend OTP</a>}

                </div>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <LoaderBtn loading={loading} title={labels.VERIFY} disabled={loading}/>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  )
    ;
}

export default OtpModal;