import React from "react";
import { ScaleLoader } from "react-spinners";

function TranscriptionLoader({ isLoading, text }) {
  return (
    <>
      {isLoading && (
        <div className="loader">
          <span className="text">{text}</span>
          <ScaleLoader height={20} speedMultiplier={1.5} color="#27bd5f" />
        </div>
      )}
    </>
  );
}

export default TranscriptionLoader;

