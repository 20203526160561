import { globals } from "./constants";

export const tokenKeys = {
  ACCESS: 'accessToken',
  REFRESH: 'refreshToken',
  LAST_LOGIN: 'lastLoginTime'
};

export const local = {
  storeTokens: (tokens) => {
    localStorage.setItem(tokenKeys.ACCESS, tokens.access_token);
    localStorage.setItem(tokenKeys.REFRESH, tokens.refresh_token);
    const currentTime = new Date().getTime();
    localStorage.setItem(tokenKeys.LAST_LOGIN, currentTime.toString())
  },
  storeAccessToken: (access_token) => {
    localStorage.setItem(tokenKeys.ACCESS, access_token);
  },
  getAccessToken: () => {
    return localStorage.getItem(tokenKeys.ACCESS);
  },
  getRefreshToken: () => {
    return localStorage.getItem(tokenKeys.REFRESH);
  },
  clearLocalStorge: () => {
    localStorage.clear();
  },
  storeUser: (user) => {
    localStorage.setItem('user', JSON.stringify(user));
  },
  getUser: () => {
    return JSON.parse(localStorage.getItem('user'));
  },
  isUserLoggedIn: () => {
    return !!local.getAccessToken() && !!local.getUser();
  },
  isLocationAllowed: () => {
    return localStorage.getItem('locationAllowed');
  },
  setIsLocationAllowed: (value) => {
    return localStorage.setItem('locationAllowed', value);
  },
  removeIsLocationAllowed: () => {
    return localStorage.removeItem('locationAllowed');
  },
};
