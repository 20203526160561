import React from "react";
import { BsArrowClockwise } from "react-icons/bs";
import { labels, transUtils } from "common";

function TranscriptionRetry({
  formik,
  setInProgress,
  token,
  setLoader,
  setRaw,
  setProcessed,
  failed,
  setFailed,
}) {
  const handleTranscriptionRetry = () => {
    setFailed(false);
    transUtils.processTranscription(
      formik, setInProgress, token, setLoader, setRaw, setProcessed, setFailed
    );
  };
  return (
    <>
      {failed && (
        <BsArrowClockwise
          onClick={handleTranscriptionRetry}
          size={20}
          className="retry"
          title={labels.RETRY_TRANSCRIPTION}
        />
      )}
    </>
  );
}

export default TranscriptionRetry;
