import React from "react";
import { Placeholder } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import { IconCardImage } from "assets/icons";
import { Link } from "react-router-dom";
import "pages/vendorDetail/_vendorDetail.scss";
import { utils } from "common";

function VendorInfo({ shop, reviews, avgRating, scrollToReviews }) {
  return (
    <div className="head">
      <div className="thumbnailHolder">
        <img
          className={!shop?.image_url ? "default" : ""}
          src={shop?.image_url || IconCardImage}
          alt=""
        />
      </div>
      <div className="nameHolder">
        {!shop?.name ? (
          <Placeholder as="h1" animation="glow" className="placeholder">
            <Placeholder xs={6} />
          </Placeholder>
        ) : (
          <h1 id="shopName">{shop?.name}</h1>
        )}
        <div className="metaData">
            <StarRatings
              starSpacing="2px"
              starDimension="18px"
              rating={avgRating ? avgRating : 0}
              starRatedColor="#6C5CE7CC"
              numberOfStars={5}
              name="rating"
            />
            <span className="avgRating">{avgRating}</span>
          <div className="reviews">
            {reviews && (
              <Link
                to={""}
                onClick={scrollToReviews}
                href="#"
              >
                ({reviews?.count ?? 0} {utils.getReviewLabel(reviews?.count)})
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VendorInfo;
