import React from 'react';
import { Modal } from "react-bootstrap";
import { labels } from "../../common";

function GalleryModal({activeIndex, setActiveIndex, images, show, setShow, name}) {
  const imagesLength = images?.length;
  const handleNext = () => {
    setActiveIndex((activeIndex + 1) % imagesLength)
  }

  const handlePrevious = () => {
    setActiveIndex((activeIndex - 1 + imagesLength) % imagesLength);
  }

  const revivalBack = () => {
    window.onpopstate = undefined;
    window.history.back();
  };

  const handleClose = () => {
    setShow(false);
    revivalBack();
  }

  return (
    <Modal id="menuModal" size="xl" show={show} fullscreen="md-down" onHide={handleClose} >
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>{name}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div id="carouselMenuItems" className="carousel carousel-fade">
          <ul className="masonry carousel-indicators" id="masonryContainer">
            {
              images?.map((src, index) => {
                return <li key={index} className={`masonry-item ${index === activeIndex && "active"}`}
                           onClick={() => setActiveIndex(index)}
                >
                  <img src={src.image_url} alt=""/>
                </li>
              })
            }
          </ul>
          <div className="carousel-inner" id="carousel-inner">
            {
              images?.map((src, index) => {
                return <div key={index} className={`carousel-item ${index === activeIndex && "active"}`}>
                  <img src={src.image_url} alt=""/>
                </div>
              })
            }
            <button className="carousel-control-prev" type="button" onClick={handlePrevious}>
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="visually-hidden">{labels.PREVIOUS}</span>
            </button>
            <button className="carousel-control-next" type="button" onClick={handleNext}>
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="visually-hidden">{labels.NEXT}</span>
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default GalleryModal;