import React from "react";
import { Link } from "react-router-dom";
import "components/areaCard/_areaCard.scss";
import { BsArrowRight } from "react-icons/bs";

function AreaCard({ area }) {
  return (
    <div className="aCard col-md-6 col-lg-4">
      <Link
        to={`/vendors/${area.id}`}
        className="holder d-flex align-items-center justify-content-center flex-column text-center"
      >
        <div className={`imgWrap ${area.image_url && "default"}`}>
          <img className="bgImage" src={area.image_url} alt="" />
        </div>

        <div className="d-flex flex-column align-items-center position-relative z-2">
          <h2>{area.area}</h2>
          <p>
            Explore
            <span className="icon">
              <BsArrowRight />
            </span>
          </p>
        </div>
      </Link>
    </div>
  );
}

export default AreaCard;

