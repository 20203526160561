import React, { useEffect, useRef, useState } from 'react';
import 'components/showMoreText/_showMoreText.scss';

function ShowMoreText({text, edited}) {
  const [showMore, setShowMore] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    const element = textRef.current;
    if (element.scrollHeight > element.clientHeight) {
      setShowButton(true);
    }
    return () => {
      setShowButton(false);
    };
  }, [text]);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="show-more-text">
      <div ref={textRef} className={`text ${showMore ? 'expanded' : ''}`}>
        { <>
            <span>{text?.trim()}</span>
            <span className="edited">{edited}</span>
          </>
        }
      </div>
      {showButton && (
        <span className="read-more" onClick={toggleShowMore}>
            {showMore ? "show less" : "read more"}
          </span>
      )}
    </div>
  );
}

export default ShowMoreText;
