import React from "react";
import { Modal } from "react-bootstrap";
import { LoaderBtn } from "components";

function DeleteModal({ show, setShow, address, handleAddressDelete, loading }) {

  return (
    <div>
      <Modal
        className=""
        id=""
        show={show}
        fullscreen="md-down"
        centered
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton style={{marginBottom: "0px"}}>
          <Modal.Title>
            <div className="modal-header">
              <h1 className="modal-title" id="reviewModalLabel2">
                Delete Address
              </h1>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="deleteText">
            Are you sure you want to delete address{" "}
            <span className="address">'{address.title}'</span>?
          </span>
            <Modal.Footer className="modal-footer">
              <LoaderBtn title={"Delete"} loading={loading} onClick={handleAddressDelete} className="btn btn-primary"/>
              <button
                onClick={() => setShow(false)}
                className="btn btn-secondary cancel"
                aria-label="Close"
                disabled={!show}
              >
                Cancel
              </button>        
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default DeleteModal;

