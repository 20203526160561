import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { BsPlusCircle, BsPencil, BsTrash } from "react-icons/bs";
import { api } from "api";
import { messages } from "common/constants";
import { useParams } from "react-router-dom";
import { DeleteModal } from "modals";
import { toast } from "react-toastify";

function AddressSelector({
  formik,
  customerAddresses,
  setCustomerAddresses,
  handleAddAddress,
  shopArea,
  newAddress,
  setNewAddress,
  setIsUpdate,
  selectedAddress,
  setSelectedAddress,
}) {
  const { token } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const filteredAddresses = customerAddresses?.filter((ca) => ca.area === shopArea?.id);

  const handleAction = (id, title, action) => {
    if (action === "update") {
      setIsUpdate(true);
    } else if (action === "delete") {
      setShowDeleteModal(true);
    }
    setSelectedAddress({ id, title });
  };

  const handleAddressDelete = async () => {
    setLoading(true);
    const id = selectedAddress.id;
    await api.deleteCustomerAddress(id);
    const addresses = await api.getCustomerAddresses(token);
    setCustomerAddresses(addresses);
    setShowDeleteModal(false);
    toast.success(messages.ADDRESS_DELETE_SUCCESS);
    const defaultRadio = document.getElementById(`address-${id}`);
    if (defaultRadio) {
      defaultRadio.checked = false;
    }
    if(formik.values.existing_address == selectedAddress.id) {
      formik.setFieldTouched("existing_address", false);
      formik.setFieldValue("existing_address", null);
    }
    if(selectedAddress.id == newAddress) {
      setNewAddress(null);
    }
    setLoading(false);
  };

  useEffect(() => {
    if(newAddress || filteredAddresses.length === 1) {
      const defaultAddressId = filteredAddresses.length === 1 ? filteredAddresses[0]?.id : newAddress;
      formik.setFieldTouched("existing_address", true);
      formik.setFieldValue("existing_address", defaultAddressId);
      const defaultRadio = document.getElementById(`address-${defaultAddressId}`);
      if (defaultRadio) {
        defaultRadio.checked = true;
      }
    }
  }, [newAddress, customerAddresses]);

  return (
    <section
      className="AddressWrap"
      style={
        formik.values.transport === "PICKUP" || formik.values.transport === null
          ? {display: "none"}
          : {}
      }
    >
      <div>
        {formik.values.transport === "DELIVERY" &&
          filteredAddresses.length > 0 &&  (
            <>
              <h3>Choose Address</h3>
              <div className="text-danger">
                {formik.touched.existing_address &&
                  formik.errors.existing_address}
              </div>
              <ul className="addressList">
                {customerAddresses?.map((ca, index) => {
                  return (
                    ca.area === shopArea?.id && (
                      <li className="radio-button-container">
                        <Form.Check
                          key={index + 1}
                          id={`address-${ca.id}`}
                          type="radio"
                          value={ca.id}
                          name="existing_address"
                          label={`${ca.title}, ${shopArea?.area}`}
                          isInvalid={
                            formik.touched.existing_address &&
                            !!formik.errors.existing_address
                          }
                          onChange={formik.handleChange}
                          defaultChecked={filteredAddresses?.length == 1 || ca.id === newAddress}
                        />
                        <div className="addressControls">
                          <BsPencil
                            className="icon"
                            onClick={() => {
                              handleAction(ca?.id, ca?.title, "update");
                            }}
                          ></BsPencil>
                          <BsTrash
                            className="icon"
                            onClick={() => {
                              handleAction(ca?.id, ca?.title, "delete");
                            }}
                          ></BsTrash>
                        </div>
                      </li>
                    )
                  );
                })}
              </ul>
              <DeleteModal
                show={showDeleteModal}
                setShow={setShowDeleteModal}
                address={selectedAddress}
                handleAddressDelete={handleAddressDelete}
                loading={loading}
              ></DeleteModal>
            </>
          )}
      </div>
      {formik.values.transport === "DELIVERY" && (
        <a onClick={handleAddAddress} className="btnAddAddress">
          <BsPlusCircle className="icon" /> Add new address
        </a>
      )}
    </section>
  );
}

export default AddressSelector;

