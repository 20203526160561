import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from 'api';

const authExtraActions = {
  login: createAsyncThunk('auth/login', api.login),
  sendOtp: createAsyncThunk('auth/sendOtp', api.sendOtp),
  checkOtp: createAsyncThunk('auth/checkOtp', api.checkOtp),
  verifyOtp: createAsyncThunk('auth/verifyOtp', api.verifyOtp),
};

export default authExtraActions;
