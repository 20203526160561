import React, { useEffect, useState } from "react";
import "components/header/_header.scss";
import { Logo } from "../../assets/icons";
import { Link, useLocation } from "react-router-dom";
import { local, routes } from "common";
import { Button, Dropdown, DropdownButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../reduxStore/reducers/authSlice";
import { useLoginContext } from "../../contexts";
import { utils } from "common";

function Header() {
  const user = local.getUser();
  const dispatch = useDispatch();
  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState(false);
  const { isUserLoggedIn } = useSelector((state) => state.auth);
  const { areas } = useSelector((state) => state.general);
  const { showLoginModal } = useLoginContext();
  const howItWorks = document.getElementById("howItWorks");

  const handleLogout = () => {
    dispatch(authActions.logout());
  };

  useEffect(() => {
    if (location.pathname == "/") {
      setIsHomePage(true);
    } else {
      setIsHomePage(false);
    }
  });

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg py-0">
          <div className="container-fluid d-flex justify-content-between">
            <Link className="navbar-brand" to={routes.HOME}>
              <img src={Logo} alt={"logo"} />
            </Link>
            <div className="btn-group">
              {isHomePage && (
                <>
                  <DropdownButton
                    variant="secondary"
                    size="sm"
                    title={
                      <span>
                        Explore Areas
                      </span>
                    }
                  >
                    {areas?.map((area) => {
                      return (
                        <Dropdown.Item className="dropdown-item">
                          <Link
                            to={`/vendors/${area.id}`}
                            className={`d-block`}
                          >
                            <p>{area.area}</p>
                            
                            <span className="stats d-block">
                              {utils.calculateStat(area.shops_count)}<span>+</span>{" "} Shops/Services
                            </span>
                          </Link>
                        </Dropdown.Item>
                      );
                    })}
                  </DropdownButton>
                  <span
                    onClick={() =>
                      howItWorks.scrollIntoView({ behavior: "smooth" })
                    }
                    className="howItWorks"
                  >
                    How It Works
                  </span>
                </>
              )}
              {isUserLoggedIn ? (
                <DropdownButton
                  className="logNum"
                  variant="secondary"
                  size="sm"
                  title={`Hi, ${user?.phoneNumber}`}
                >
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </DropdownButton>
              ) : (
                <div id="authContainer">
                  <Button
                    id="loginButton"
                    variant="primary"
                    onClick={() => showLoginModal()}
                  >
                    Sign In
                  </Button>
                </div>
              )}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
}

export default Header;

