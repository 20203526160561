import React from "react";

function ExpandingNetwork() {
  return (
    <div className="container">
        <div class="head col-lg-12">
          <h2>Our Expanding Network</h2>
          <p>Stay tuned as we expand to new neighborhoods soon!</p>
        </div>
        <div className="statsHolder row">
          <div className="col-lg-4 col-md-6">
            <div className="card">
            <div className="head">
              <h5>
                3<span>+</span>
              </h5>
              <span>Areas</span>
            </div>
            <p>
              Serving multiple neighborhoods in Lahore, and we're constantly
              expanding to <span>bring more areas</span> into our network.
            </p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6">
            <div className="card">
            <div className="head">
              <h5>
                20<span>+</span>
              </h5>
              <span>Categories</span>
            </div>
            <p>
              Whether you're looking for <span>groceries</span>, <span>laundry services</span>, or
              specialty items, our wide range of categories ensures you find
              exactly what you need.
            </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="card">
            <div className="head">
              <h5>
                800<span>+</span>
              </h5>
              <span>Shops</span>
            </div>
            <p>
              From your favorite local <span>bakery</span> to essential <span>medical stores</span>, we've
              got it all.
            </p>
            </div>
          </div>
        </div>{" "}

    </div>
  );
}

export default ExpandingNetwork;

