
import React from 'react';
import { Modal, Button } from "react-bootstrap";
import 'modals/otpModal/_otpModal.scss';
import { QRCode } from "assets/images";
import { IconInfoSmall, IconBackSmall } from "../../assets/icons";

function WhatsappModal({show, setShow, handleBack}) {

  const handleClick = () => {
    window.open('https://wa.me/3173282328?text=Send%20me%20OTP', '_blank');
  }

    return (
        <Modal className='loginModal' show={show} fullscreen="md-down" onHide={() => setShow(false)} centered>
          <Modal.Header closeButton>
          <div className='smallTitle'><a onClick={handleBack} style={{"cursor":"pointer"}}><img src={IconBackSmall} alt="" /></a><span>Open <span className='waColor'>WhatsApp</span> to Receive OTP</span></div>
            <Modal.Title>Get OTP via WhatsApp</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{marginBottom:0, rowGap:24}}>
            <div className='infoBox'>
              <img src={IconInfoSmall} alt="info Icon"/>
              <span>Send us a message saying <strong>'Send me OTP'</strong> to our WhatsApp number.</span>
            </div> 
            <div className='getOTPWrap'>
              <div className='QrCodeWrap'>
              <img src={QRCode} alt='QR Code'></img>
                <span>Scan QR Code from Your Mobile</span>
              </div>
                <span className='or-divider'></span>
                <Modal.Footer>
                  <button className='btn btnWhatsApp' onClick={handleClick} >Get OTP via WhatsApp</button>
                  
                </Modal.Footer>
            </div>
          </Modal.Body>
        </Modal>
)};

export default WhatsappModal