import React, { useEffect, useState } from "react";
import { AddAddress, EditAddress } from "modals";
import { Modal } from "react-bootstrap";
import { globals } from "common";

function AddressModal({
  centroid,
  shopArea,
  show,
  setShow,
  setCustomerAddresses,
  setNewAddress,
  selectedAddress,
  setSelectedAddress,
  isUpdate,
  setIsUpdate,
}) {
  const [showEditModal, setShowEditModal] = useState(false);
  const [pin, setPin] = useState(null);
  const [address, setAddress] = useState(globals.DEFAULT_ADDRESS);

  const closeModal = () => {
    setShow(false);
    setIsUpdate(false);
    setShowEditModal(false);
    setSelectedAddress({id: null, title: ""});
  };

  useEffect(() => {
    if (isUpdate) {
      setShow(true);
    }
  }, [isUpdate]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function (position) {
        setPin({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      () => {
        setPin(centroid ? centroid : globals.DEFAULT_PIN);
      }
    );
  }, []);

  return (
    <div>
      <Modal
        className="dAddressModal"
        show={show}
        fullscreen="md-down"
        centered
        onHide={closeModal}
      >
        {!showEditModal ? (
          <AddAddress
            shopArea={shopArea}
            address={address}
            setAddress={setAddress}
            setCustomerAddresses={setCustomerAddresses}
            setShowEditModal={setShowEditModal}
            show={show}
            setShow={setShow}
            pin={pin}
            setPin={setPin}
            setNewAddress={setNewAddress}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            isUpdate={isUpdate}
            setIsUpdate={setIsUpdate}
          ></AddAddress>
        ) : (
          <EditAddress
            shopArea={shopArea}
            address={address}
            setAddress={setAddress}
            setShow={setShow}
            setShowEditModal={setShowEditModal}
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            isUpdate={isUpdate}
          ></EditAddress>
        )}
      </Modal>
    </div>
  );
}

export default AddressModal;

