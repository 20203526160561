import React from 'react';
import { Button, Modal } from "react-bootstrap";
import { ReviewCompleteImage } from "../../assets/images";
import "modals/successModal/_successModal.scss";

function SuccessModal({show, setShow}) {
    
  return (
    <div>
        <Modal className='successModal'  id="reviewModal2" show={show} fullscreen="md-down" centered onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <div className="modal-header">
                        <img src={ReviewCompleteImage} alt=""/>
                        <h1 className="modal-title" id="reviewModalLabel2">Review Submitted Successfully</h1>
                        <h3>Thank you for sharing your experience!</h3>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Modal.Footer className='modal-footer'>
                    <Button onClick={() => setShow(false)} className="btn btn-primary">Okay</Button>
                </Modal.Footer>
            </Modal.Body>  
        </Modal>
    </div>
  )
}

export default SuccessModal;

