import React from "react";
import { Form } from "react-bootstrap";
import "pages/webOrder/_webOrder.scss";

function ShopProducts({ products, handleProductChange, source }) {
  const isWebOrder = source === "Web";

  return products?.length > 0 ? (
    <div
      className={isWebOrder ? "itemsDropdown" : "input-wrapper"}
      id="product-section"
    >
      {!isWebOrder && <p>Shop Products</p>}
      <Form.Select
        id="shop"
        className={isWebOrder && "DropdownButton"}
        name="shop"
        onChange={handleProductChange}
        value={""}
      >
        <option id="defaultShop" value={""} selected>
          <span className="select">
            Select Products{" "}
            <span className="products">(Products to add to your order)</span>
          </span>
        </option>
        {products.map((product, index) => {
          return (
            <option
              key={index}
              id="defaultShop"
              value={product.name}
            >{`${product.name} - ${product.price} Rs`}</option>
          );
        })}
      </Form.Select>
    </div>
  ) : null;
}

export default ShopProducts;

