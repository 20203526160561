import React, { useEffect, useState } from "react";
import { Modal, Placeholder } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { BsPencilSquare, BsPlusCircle } from "react-icons/bs";
import { LoaderBtn, Map } from "components";
import { labels, utils } from "common";
import { messages } from "common/constants";
import { api } from "api";
import { toast } from "react-toastify";
import "modals/addressModals/_addressModals.scss";

function AddAddress({
  address,
  setAddress,
  shopArea,
  setShow,
  setCustomerAddresses,
  setShowEditModal,
  pin,
  setPin,
  setNewAddress,
  selectedAddress,
  setSelectedAddress,
  isUpdate,
  setIsUpdate,
}) {
  const {token} = useParams();
  const [loading, setLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const areaName = shopArea?.area;

  async function handleAddAddress() {
    if (!token) return;
    setLoading(true);
    try {
      let ad = {address: utils.getAddressString(address), area: shopArea?.id};
      const res = await api.createCustomerAddresses(token, ad);
      const addresses = await api.getCustomerAddresses(token);
      setNewAddress(res?.id);
      setCustomerAddresses(addresses);
      toast.success(messages.ADDRESS_SUCCESS);
      setShow(false);
    } catch (error) {
      utils.showErrorToast(error);
    }
    setLoading(false);
  }

  async function handleUpdateAddress() {
    setLoading(true);
    await api.updateCustomerAddress(selectedAddress.id, {
      address: selectedAddress.title,
    });
    const addresses = await api.getCustomerAddresses(token);
    setCustomerAddresses(addresses);
    toast.success(messages.ADDRESS_UPDATE_SUCCESS);
    setLoading(false);
    setShow(false);
    setIsUpdate(false);
  }

  const handleEditAddress = () => {
    setShowEditModal(true);
  };

  useEffect(() => {
    if (isUpdate && address?.plot) {
      setSelectedAddress({
        id: selectedAddress.id,
        title: utils.getAddressString(address),
      });
    }
  }, [address]);

  return (
    <div>
      <div>
        <Modal.Header closeButton>
          <Modal.Title as="h1">Pinpoint Your Delivery Address</Modal.Title>
          <p>
            Select your precise location in <strong>"{areaName}"</strong> for
            accurate delivery.
          </p>
          {showAlert && (
            <span className="error">
              <p>{errorMsg}</p>
            </span>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="mapWrap" style={{width: "100%"}}>
            {pin?.longitude && pin?.latitude ? (
              <Map
                shopArea={shopArea}
                pin={{
                  longitude: pin.longitude,
                  latitude: pin.latitude,
                }}
                setPin={setPin}
                draggable={true}
                setAddress={setAddress}
                setShowAlert={setShowAlert}
                setErrorMsg={setErrorMsg}
              ></Map>
            ) : (
              <Placeholder
                as="h1"
                animation="glow"
                className="placeholder"
                style={{
                  width: "618px",
                  height: "450px",
                  color: "#D0D1D3",
                }}
              >
                <Placeholder xs={6}/>
              </Placeholder>
            )}
          </div>

          <div className="PreviewWrap">
            {(address?.plot || isUpdate) && <h4>Preview Address:</h4>}
            <div className="pBody">
              {address?.plot || isUpdate ? (
                <>
                  <span>
                    {isUpdate ? (
                      <span>{selectedAddress.title}</span>
                    ) : (
                      `${utils.getAddressString(address)}, ${areaName}`
                    )}
                  </span>
                  <a className="btnEdit" onClick={handleEditAddress}>
                    <BsPencilSquare />
                    Edit
                  </a>
                </>
              ) : (
                <a className="addAddress" onClick={handleEditAddress}>
                  <BsPlusCircle
                    className="icon"
                    style={{marginRight: "8px"}}
                  />
                  <span>Add Address Manually</span>
                </a>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <LoaderBtn
            onClick={isUpdate ? handleUpdateAddress : handleAddAddress}
            loading={loading}
            title={isUpdate ? labels.UPDATE_ADDRESS : labels.ADD_ADDRESS}
            disabled={!address.plot || loading || showAlert}
          ></LoaderBtn>
        </Modal.Footer>
      </div>
    </div>
  );
}

export default AddAddress;

