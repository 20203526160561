import React from 'react';
import { IconCardImage, IconCheckCircle, IconLocation } from "../../assets/icons";
import { Schedule } from '../index';
import { useNavigate } from "react-router-dom";
import StarRatings from 'react-star-ratings';
import 'pages/vendors/_vendors.scss';

function Vendor({shop}) {
  const navigate = useNavigate();

  const navigateToDetailPage = () => {
    navigate(`/vendor-detail/${shop.id}/`)
  }

  return (<li onClick={navigateToDetailPage} className="vendorCard">
      <div className="imgWrap">
        <img className={!shop?.image_url ? "default" : ""} src={shop?.image_url || IconCardImage} alt=""/>
      </div>
      <div className="textWrap">
        <h3>{shop?.name}</h3>
        <ul>
          <li>
            <div className="metaData">
              <StarRatings starSpacing='2px' starDimension='16px'
                           rating={shop?.average_rating != null ? shop?.average_rating : 0}
                           starRatedColor="#6C5CE7CC" numberOfStars={5} name='rating'
              />
            </div>
          </li>
          <li>
            <span><img src={IconLocation} alt=""/></span>
            <span>{shop?.address?.address || "Not Available"}</span>
          </li>
          <li>
            <Schedule shop={shop}></Schedule>
          </li>
          <li>
            {shop?.is_delivery_offered &&
              <div className="dService">
                <span>Delivery Service</span>
                <span><span><img src={IconCheckCircle} alt=""/></span></span>
              </div>
            }
            {shop?.is_pickup_offered &&
              <div className="dService">
                <span>Pickup Service</span>
                <span><span><img src={IconCheckCircle} alt=""/></span></span>
              </div>
            }
          </li>
        </ul>
      </div>
    </li>
  );
}

export default Vendor;
