import React from "react";
import { WhatsAppIcon } from "assets/icons";
import { MobileImage } from "assets/images";

function ChatBot() {
  return (
    <div className="container">
      <div className="row">
        <div className="holder">
          <div className="textWrap">
            <div className="head" role="heading">
              <p>Connect with Local Shops via</p>
              <h5>WhatsApp ChatBot</h5>
            </div>
            <p>
              Use our WhatsApp <strong>chatbot</strong> to easily find and
              connect with shops in your area. Get real-time updates, place
              orders, and stay connected with your favorite local businesses.
            </p>
            <a
              className="btn"
              onClick={() => window.open("https://wa.me/3173282328", "_blank")}
            >
              get started{" "}
              <span>
                <img src={WhatsAppIcon} alt="" />
              </span>
            </a>
          </div>
          <img src={MobileImage} alt="" />
        </div>
      </div>
    </div>
  );
}

export default ChatBot;

