import React from "react";
import { icoLinkArrow } from "assets/icons";

function Benefits({ benefits, scrollToAreas }) {
  return (
    <div className="container">
      <div className="contentHolder">
        <div className="wrap listWrap">
          <ul>
            {benefits.benefits.map((benefit, index) => {
              return (
                <li key={index}>
                  <h5>{benefit.title}</h5>
                  <p>{benefit.description}</p>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="wrap textArea">
          <div className="holder">
            <h6>
              Unlock <br />
              the <span>Benefits</span> <br />
              of Using Fatafat
            </h6>
            <a className="link" onClick={scrollToAreas}>
              Discover local businesses in your community <img src={icoLinkArrow} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefits;

