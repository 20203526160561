import React from "react";
import { Form } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";
import { utils } from "common";

function DeliveryOptions({ shop, formik }) {
  return (
    <div>
      <div class="head">
        <h2>Choose Pickup or Delivery</h2>
      </div>
      <div className="optionWrap">
        <OverlayTrigger
          placement="top"
          overlay={utils.renderTooltip("Pick Up")}
          trigger={!shop?.is_pickup_offered ? "hover" : []}
        >
          <div>
            <Form.Check
              id="transport-1"
              type="radio"
              name="transport"
              value="PICKUP"
              label="Pick Up"
              className={!shop?.is_pickup_offered ? "disabled" : ""}
              onChange={formik.handleChange}
              defaultChecked={formik.values.transport == "PICKUP"}
            />
          </div>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={utils.renderTooltip("Delivery")}
          trigger={!shop?.is_delivery_offered ? "hover" : []}
        >
          <div>
            <Form.Check
              id="transport-2"
              type="radio"
              name="transport"
              value="DELIVERY"
              label="Delivery"
              className={!shop?.is_delivery_offered ? "disabled" : ""}
              onChange={formik.handleChange}
              defaultChecked={formik.values.transport == "DELIVERY"}
            />
          </div>
        </OverlayTrigger>
      </div>
      <div className="text-danger">
        {formik.touched.transport && formik.errors.transport}
      </div>
    </div>
  );
}

export default DeliveryOptions;

