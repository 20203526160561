import React from 'react';
import { utils } from "../../common";

function Time({ isCurrent, day, currentSlot, time_slots, closed=false }) {
  
  const renderTimeSlot = (timeSlot) => (
    (timeSlot.opening_time && timeSlot.closing_time) ? (
      <span>
        <span className="open">{utils.to12HourFormat(timeSlot?.opening_time)}</span> - {" "}
        <span className="close">{utils.to12HourFormat(timeSlot?.closing_time)}</span>
      </span>
    ) : (
      (time_slots?.length == 1 || currentSlot) && <span className="close">Closed</span>
    )
  );
  
  const renderTimeSlots = (timeSlots) => (
    <div class='multipleSlots'>
      {timeSlots.map((timeSlot) => (
          <span key={timeSlot.id}>
            {renderTimeSlot(timeSlot)}
          </span>
      ))}
    </div>
  );
  
  
  const renderCurrentSlot = (currentSlot) => (
    <>
      {renderTimeSlot(currentSlot)}
    </>
  );
  
  return (
    <span className="ocTime">
    {day ? (
      <span className={isCurrent ? "currentDay" : "dayName"}>{`${day} `}</span>
    ) : (
      "Today "
    )}
    {!closed ? (
      currentSlot ? renderCurrentSlot(currentSlot) : renderTimeSlots(time_slots)
    ) : (
      <span className="close">Closed</span>
    )}
  </span>
  );
}

export default Time;
