import React from 'react';
import ReactDOM from 'react-dom/client';
import 'index.scss';
import App from 'App';
import { Provider } from 'react-redux';
import reportWebVitals from 'reportWebVitals';
import store from "reduxStore/store";
import 'bootstrap/dist/css/bootstrap.min.css';
import { LoginProvider } from "./contexts";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <LoginProvider>
        <App/>
      </LoginProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
