import React, { useEffect, useRef, useState } from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { Form } from "react-bootstrap";
import { ShopProducts, TranscriptionLoader, TranscriptionRetry } from "components";
import { ImgCancel } from "assets/images";
import { BsFillMicFill } from "react-icons/bs";
import { useParams } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";
import { transUtils } from "common";

function OrderItems({
  products,
  handleProductChange,
  formik,
  handleAudioDelete,
  transcriptionInProgress,
  setTranscriptionInProgress,
}) {
  const { token } = useParams();
  const recorderRef = useRef(null);
  const recorderControls = useAudioRecorder();
  const [audioBlob, setAudioBlob] = useState(null);
  const [transcriptionLoader, setTranscriptionLoader] = useState(null);
  const [rawTranscription, setRawTranscription] = useState(null);
  const [processedTranscription, setProcessedTranscription] = useState(null);
  const [transFailed, setTransFailed] = useState(false);

  const handleRecordingComplete = (blob) => {
    formik.setFieldValue("order_audio", blob);
  };

  useEffect(() => {
    if (recorderRef.current) {
      const ele = recorderRef.current.querySelector(
        '[data-testid="ar_cancel"]'
      );
      ele?.setAttribute("title", "Stop recording");
      recorderRef.current.querySelector(".audio-recorder-mic")?.remove();
    }
  }, []);

  useEffect(() => {
    recorderControls.isRecording && setRawTranscription(null);
  }, [recorderControls]);

  useEffect(() => {
    if (formik.values.order_audio) {
      setAudioBlob(URL.createObjectURL(formik.values.order_audio));
    }
    transUtils.processTranscription(
      formik, setTranscriptionInProgress, token, setTranscriptionLoader,
      setRawTranscription, setProcessedTranscription, setTransFailed,
    );
  }, [formik.values.order_audio]);

  return (
    <div className="orderWrap">
      <div className="holder">
        <ShopProducts
          products={products}
          handleProductChange={handleProductChange}
          source={"Web"}
        />
        <Form.Control
          as="textarea"
          name="order_text"
          id="order-text"
          placeholder="Type or dictate your custom order here (e.g., milk - 1kg, oil - 2L)"
          value={formik.values.order_text}
          onChange={formik.handleChange}
          isInvalid={formik.touched.order_text && !!formik.errors.order_text}
        ></Form.Control>
        <div className={formik.values.order_audio && "voiceNoteHolder"}>
          {formik.values.order_audio && (
            <>
              <audio
                id="audio-play"
                src={audioBlob}
                className="audio-play"
                controls
              ></audio>
              <a
                id="audio-delete"
                className="audio-delete"
                onClick={handleAudioDelete}
              >
                <img width={20} height={20} src={ImgCancel} alt="x" />
              </a>
              <TranscriptionRetry formik={formik} setInProgress={setTranscriptionInProgress} token={token}
               setLoader={setTranscriptionLoader} setRaw={setRawTranscription} setProcessed={setProcessedTranscription} 
               failed={transFailed} setFailed={setTransFailed}/>
            </>
          )}
        </div>
        <div className="text-danger items">
          {formik.touched.order_text && formik.errors.order_text}
        </div>
        {rawTranscription && processedTranscription == null ? (
          <>
            <TypeAnimation
              sequence={[rawTranscription]}
              wrapper="span"
              className="transWrap"
              speed={80}
              repeat={0}
            />
          </>
        ) : processedTranscription ? (
          <span className="transWrap">{rawTranscription}</span>
        ) : null}
      </div>

      <div ref={recorderRef} className="audioContainer">
        <a
          className={`btn-AddVoice ${
            transcriptionInProgress ? "disabled" : ""
          }`}
          onClick={
            recorderControls.isRecording
              ? () => {}
              : recorderControls.startRecording
          }
          style={recorderControls.isRecording ? { width: "240px" } : {}}
        >
          {!recorderControls.isRecording && <BsFillMicFill className="icon" />}
          <AudioRecorder
            recorderControls={recorderControls}
            onRecordingComplete={handleRecordingComplete}
            downloadOnSavePress={false}
            audioTrackConstraints={{
              noiseSuppression: true,
              echoCancellation: true,
            }}
            downloadFileExtension="mp3"
          />
          {!recorderControls.isRecording && <span>Add Voice Note</span>}
        </a>
        <TranscriptionLoader
          isLoading={transcriptionInProgress}
          text={transcriptionLoader}
        />
      </div>
    </div>
  );
}

export default OrderItems;
