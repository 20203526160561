import React from 'react';
import {Footer, Header} from 'components';
import {Outlet} from 'react-router-dom'
import 'components/layout/_layout.scss';

function Layout() {
    return (<div className={'page-container'}>
        <Header/>
        <main>
            <Outlet/>
        </main>
        <Footer/>
    </div>);
}

export default Layout;