import React from 'react';
import { Pagination } from "react-bootstrap";
import "components/customPagination/_customPagination.scss";

function CustomPagination({totalPages, pageNumber, setPageNumber}) {

  const handleFirstPage = () => {
    setPageNumber(1);
  };

  const handleLastPage = () => {
    setPageNumber(totalPages);
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleNextPage = () => {
    if (pageNumber < totalPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const renderPaginationItems = () => {
    const items = [];
    const maxPagesToShow = 5;
    const startPage = Math.max(1, pageNumber - Math.floor(maxPagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      items.push(
        <Pagination.Item
          key={i}
          active={i === pageNumber}
          onClick={() => setPageNumber(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <div className="d-flex justify-content-center">
      <Pagination>
        <Pagination.First onClick={handleFirstPage}/>
        <Pagination.Prev onClick={handlePreviousPage}/>
        {totalPages > 0 && renderPaginationItems()}
        <Pagination.Next onClick={handleNextPage}/>
        <Pagination.Last onClick={handleLastPage}/>
      </Pagination>
    </div>
  );
}

export default CustomPagination;