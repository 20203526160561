import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { local, utils } from "common";
import { authExtraActions } from "reduxStore/actions";
import { LoginModal, OTPModal, WhatsappModal } from "modals";

const LoginContext = createContext();

export const LoginProvider = ({ children }) => {
  const user = local.getUser();
  const dispatch = useDispatch();
  
  const {
    isUserLoggedIn,
    isOTPSent,
    inside24Limit,
    loading: authLoading,
    errors: authErrors
  } = useSelector((state) => state.auth);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showWhatsAppModal, setShowWhatsAppModal] = useState(false);
  const [countDown, setCountDown] = useState(60);

  const handleLoginSubmit = async (values) => {
    local.storeUser({phoneNumber: values.phoneNumber});
    dispatch(authExtraActions.sendOtp({phone_number: values.phoneNumber}));
  };

  const handleOTPSubmit = async (values) => {
    dispatch(authExtraActions.verifyOtp({otp: values.otp, phone_number: user?.phoneNumber}));
  };

  const handleBack = () => {
    setShowWhatsAppModal(false);
    setShowLoginModal(true);
  };

  useEffect(() => {
    if (isOTPSent) {
      setShowLoginModal(false);
      if (inside24Limit) {
        showWhatsAppModal && setShowWhatsAppModal(false);
        setShowOTPModal(true);
        setCountDown(60);
      } else {
        setShowWhatsAppModal(true);
      }
    }
  }, [isOTPSent, inside24Limit]);

  useEffect(() => {
    // Polling to check if OTP has been generated and then showing user appropriate login modal
    let intervalId = null;
  
    if (showWhatsAppModal) {
      intervalId = setInterval(() => {
        dispatch(authExtraActions.checkOtp({ phone_number: user?.phoneNumber }));
      }, 2000);
    }
  
    if (isOTPSent && inside24Limit) {
      setShowWhatsAppModal(false);
      setShowOTPModal(true);
    }
  
    return () => {
      clearInterval(intervalId);
    };
  }, [dispatch, showWhatsAppModal]);
  

  useEffect(() => {
    isUserLoggedIn && setShowOTPModal(false);
  }, [isUserLoggedIn])

  useEffect(() => {
    if (authErrors) {
      utils.showErrorToast(authErrors);
      setShowLoginModal(false);
      setShowWhatsAppModal(false);
      setShowOTPModal(false);
    }
  }, [authErrors]);
  return (
    <LoginContext.Provider
      value={{
        showLoginModal: () => setShowLoginModal(true)
      }}
    >
      {children}
      <LoginModal show={showLoginModal} setShow={setShowLoginModal} onSubmit={handleLoginSubmit}
        loading={authLoading}/>
      <WhatsappModal show={showWhatsAppModal} setShow={setShowWhatsAppModal} handleBack={handleBack}/>
      <OTPModal show={showOTPModal} setShow={setShowOTPModal} onSubmit={handleOTPSubmit}
        loading={authLoading} inside24Limit={inside24Limit} countDown={countDown} setCountDown={setCountDown}/>
    </LoginContext.Provider>
  );
};

export const useLoginContext = () => {
  return useContext(LoginContext);
};
