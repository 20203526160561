import React from "react";
import { AreaCard } from "components";
import { Placeholder } from "react-bootstrap";

function Areas({ areas, loading }) {
  return (
    <div className="container">
      <div className="head col-lg-12">
        <h1>Explore your area</h1>
        <p>
          Select your area to start shopping and accessing services tailored to
          your location.
          <br />
          We're continually expanding, so stay tuned for more areas soon!
        </p>
        <span className="areaTab">Lahore</span>
      </div>
      <div className="cityCards d-flex row flex-wrap">
        {loading ? (
          <div className="placeholderContainer">
            {[...Array(6)].map((_, index) => (
              <Placeholder
                as="div"
                animation="glow"
                className="outer"
              >
                <Placeholder
                  xs={12}
                  className="inner"
                />
              </Placeholder>
            ))}
          </div>
        ) : (
          areas?.map((area, index) => {
            return <AreaCard area={area} key={index} />;
          })
        )}
      </div>
    </div>
  );
}

export default Areas;

