import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { IconBackSmall } from "assets/icons";
import { Formik } from "formik";
import { globals, schemas, utils } from "common";
import { api } from "api";

function EditAddress({
  shopArea,
  address,
  setAddress,
  setShow,
  setShowEditModal,
  selectedAddress,
  setSelectedAddress,
  isUpdate,
}) {
  const [sectors, setSectors] = useState([]);
  const [blocks, setBlocks] = useState([]);
  let [house, setHouse] = useState(null);
  let [street, setStreet] = useState(null);
  let [block, setBlock] = useState(null);

  const handleFormSubmit = (values) => {
    const addr = {
      plot: values.houseNumber,
      block: values.block,
      sector: values.sector,
      street: values.streetNumber,
    };
    setAddress(addr);
    setSelectedAddress({
      id: selectedAddress.id,
      title: utils.getAddressString(addr),
    });
    setShowEditModal(false);
    setShow(true);
  };

  const handleBack = () => {
    setShowEditModal(false);
    setShow(true);
  };

  const handleSectorChange = (e) => {
    const sector = sectors[e.target.value - 1];
    setBlocks(sector?.blocks);
  };

  useEffect(() => {
    if (selectedAddress && isUpdate) {
      const parsedAddress = utils.parseAddress(selectedAddress?.title);
      setHouse(parsedAddress?.house);
      setStreet(parsedAddress?.street);
      setBlock(parsedAddress?.block);
    }
  }, [selectedAddress]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (shopArea?.id === globals.BAHRIA_TOWN_ID) {
          const sectors = await api.getSectors();
          setSectors(sectors);
        }
        let areaName = "";
        if (address.block && address.block.startsWith(shopArea?.area)) {
          let splitString = address.block.split(/\bblock\b/i);
          areaName = splitString[0].trim();
        } else {
          areaName = shopArea?.area;
        }
        const blocks = await api.getBlocks({params: {area: areaName}});
        setBlocks(blocks);
      } catch (error) {
        utils.showErrorToast(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <Modal.Header closeButton>
        <a className="mBackTop" onClick={handleBack}>
          <img src={IconBackSmall} alt=""/>
        </a>
        <Modal.Title as="h1">Edit Address ({shopArea?.area})</Modal.Title>
        <Modal.Title as="h2">Edit and Confirm your address.</Modal.Title>
      </Modal.Header>
      <Formik
        validationSchema={schemas.newAddress}
        onSubmit={handleFormSubmit}
        initialValues={{
          sector: address?.sector,
          block: block ? block : address?.block,
          streetNumber: street ? street : address?.street,
          houseNumber: house ? house : address?.plot,
        }}
        enableReinitialize={true}
      >
        {({
          handleSubmit,
          isSubmitting,
          handleChange,
          values,
          touched,
          errors,
          isValid,
        }) => (
          <Form className="order-form" onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="editAddWrap">
                {sectors.length > 0 && (
                  <div>
                    <label htmlFor="sector">Sector </label>
                    <Form.Select
                      id="sector"
                      type="text"
                      name="sector"
                      value={values.sector}
                      isInvalid={touched.sector && !!errors.sector}
                      onChange={handleSectorChange}
                    >
                      <option value={null} selected>
                        <span className="select">Select Sector</span>
                      </option>
                      {sectors?.map((sector, index) => {
                        return (
                          <option
                            key={index}
                            id="defaultShop"
                            value={sector?.id}
                          >{`${sector?.name}`}</option>
                        );
                      })}
                    </Form.Select>
                    <div className="text-danger">
                      {touched.sector && errors.sector}
                    </div>
                  </div>
                )}
                {blocks.length > 0 && (
                  <div>
                    <label htmlFor="block">Block </label>
                    <Form.Select
                      id="block"
                      type="text"
                      name="block"
                      value={values.block}
                      isInvalid={touched.block && !!errors.block}
                      onChange={handleChange}
                    >
                      <option value={null}>
                        <span className="select">Select Block</span>
                      </option>
                      {blocks?.map((block, index) => {
                        return (
                          <option
                            key={index}
                            value={block?.name}
                          >{`${block?.name}`}</option>
                        );
                      })}
                    </Form.Select>
                    <div className="text-danger">
                      {touched.block && errors.block}
                    </div>
                  </div>
                )}
                <div>
                  <label htmlFor="streetNumber">Street #</label>
                  <Form.Control
                    placeholder="Enter Street No."
                    id="street"
                    type="text"
                    name="streetNumber"
                    value={values.streetNumber}
                    isInvalid={touched.streetNumber && !!errors.streetNumber}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="houseNumber">House or Commercial #</label>
                  <Form.Control
                    placeholder={"Enter House/Commercial No."}
                    id="house"
                    type="text"
                    name="houseNumber"
                    value={values.houseNumber}
                    isInvalid={touched.houseNumber && !!errors.houseNumber}
                    onChange={handleChange}
                  />
                  <div className="text-danger">
                    {touched.houseNumber && errors.houseNumber}
                  </div>
                </div>

                {address.plot && (
                  <div>
                    <div className="PreviewWrap">
                      <h4>Preview Address:</h4>
                      <div className="pBody">
                        <span>{utils.getAddressString(address)}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                disabled={!isValid || isSubmitting}
                className="btn btn-primary"
              >
                Confirm Changes
              </button>
              <button
                className="btn btn-cancel"
                onClick={() => {
                  setShow(false);
                }}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default EditAddress;

