import React from "react";
import { Modal } from "react-bootstrap";
import { LoaderBtn } from "components";
import { useDispatch } from "react-redux";
import { generalActions } from "reduxStore/reducers/generalSlice";
import { labels } from "common";

function LocationConsentModal({ show, setShow }) {
  const dispatch = useDispatch();

  const handleAccept = () => {
    setShow(false);
    dispatch(generalActions.setPermission(true));
  };

  const handleDeny = () => {
    setShow(false);
    dispatch(generalActions.setPermission(false));
  };

  return (
    <div>
      <Modal
        className="locationModal"
        show={show}
        fullscreen="md-down"
        centered
        onHide={() => setShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Allow Access to Your Location</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span className="locationText">
            To offer you the best shopping options nearby, please share your
            location with us.
          </span>
          <Modal.Footer className="modal-footer">
            <LoaderBtn
              title={labels.ALLOW}
              onClick={handleAccept}
              className="btn btn-primary"
            />
            <button onClick={handleDeny} className="btn btn-secondary cancel">
              {labels.DENY}
            </button>
          </Modal.Footer>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default LocationConsentModal;

