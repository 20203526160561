import React from 'react';
import { Button, Spinner } from "react-bootstrap";

function LoaderBtn({id, title, loading, type="submit", disabled=false, onClick = () => {}}) {

  return (
    <Button id={id} type={type} variant="primary" disabled={disabled} onClick={onClick}>
      {loading ? <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        /> :
        title
      }
    </Button>
  );
}

export default LoaderBtn;