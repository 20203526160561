import React from 'react';

function MenuLink({menuItems}) {
  menuItems = menuItems ? menuItems.filter(item => item.link) : [];

  return (
    menuItems.length > 0 && (
      <div className="menuLinkContainer">
        {menuItems?.length === 1 ? (
          <p>Discover offerings through an
            <a className="menuLink" target="_blank" href={menuItems[0].link}> external menu link</a>
          </p>
        ) : (
          <>
            <p>
              Discover offerings through external menu links
            </p>
            <ul className="linkList">
              {menuItems?.map((item, index) => (
                <li key={index}>
                  <a className="menuLink" target="_blank" href={item?.link}>Menu Link #{index + 1}</a>
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    )
  );
}

export default MenuLink;