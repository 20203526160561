import React, { useEffect, useState } from "react";
import { NextIcon, PrevIcon } from "assets/icons";
import Slider from "react-slick";

let slidesToShow = 7;

function NextArrow(props) {
  const { className, style, onClick, slideCount, currentSlide, categories } = props;
  return (
    currentSlide !== slideCount - slidesToShow && categories > slidesToShow && (
      <div
        className={className}
        style={{ ...style, cursor: "pointer" }}
        onClick={onClick}
      >
        <img src={NextIcon}></img>
      </div>
    )
  );
}

function PrevArrow(props) {
  let { className, style, onClick, currentSlide, categories } = props;
  
  if(currentSlide < 0) {
    currentSlide = 0;
  }
  
  return (
    categories > 0 && currentSlide !== 0 && (
      <div
        className={className}
        style={{ ...style, cursor: "pointer" }}
        onClick={onClick}
      >
        <img src={PrevIcon}></img>
      </div>
    )
  );
}

function Categories({ categories, setCategoryId, currentCategoryId }) {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", () => {
      setWidth(window.innerWidth);
    });
  }, []);

  if (width <= 480) {
    slidesToShow = 2;
  } else if (width > 426 && width <= 750) {
    slidesToShow = 3;
  } else if (width > 750 && width <= 950) {
    slidesToShow = 4;
  } else if (width > 950 && width <= 1024) {
    slidesToShow = 6;
  } else {
    slidesToShow = 7;
  }

  const settings = {
    className: "slider variable-width",
    infinite: false,
    centerMode: false,
    slidesToShow: slidesToShow,
    slidesToScroll: 2,
    variableWidth: true,
    nextArrow: <NextArrow categories={categories.length} />,
    prevArrow: <PrevArrow categories={categories.length} />,
  };

  return (
    <div className="container">
      <Slider {...settings} ref={slider => {
        currentCategoryId && slider?.slickGoTo(categories?.findIndex(category => category.id === parseInt(currentCategoryId)))
      }}>
        {categories.map((category, index) => {
          return (
            <li key={index}>
              <span
                onClick={() =>
                  setCategoryId(
                    category.id == currentCategoryId ? null : category.id
                  )
                }
                className={`${currentCategoryId == category.id && "active"}`}
              >
                {category.name}
              </span>
            </li>
          );
        })}
      </Slider>
    </div>
  );
}

export default Categories;

