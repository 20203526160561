import { Step1, Step2, Step3, Step4, Step5 } from "assets/images";
import Slider from "react-slick";
import { NextArrowIcon, PrevArrowIcon } from "assets/icons";
import "components/home/howItWorks/_howItWorks.scss";
import { useRef } from "react";

function HowItWorks({steps}) {
  let sliderRef = useRef(null);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  function NextArrow({onClick}) {
    return (
      <div
        className="custom-arrow next-arrow"
        onClick={onClick}
      >
        <div className="control next">
          <img src={NextArrowIcon}></img>
        </div>
      </div>
    );
  }

  function PrevArrow({onClick}) {
    return (
      <div
        className="custom-arrow prev-arrow"
        onClick={onClick}
      >
        <div className="control prev">
          <img src={PrevArrowIcon}></img>
        </div>
      </div>
    );
  }

  const settings = {
    className: "slider",
    infinite: true,
    speed: 700,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  };

  const getIcon = (index) => {
    switch (index) {
      case 1:
        return Step1;
      case 2:
        return Step2;
      case 3:
        return Step3;
      case 4:
        return Step4;
      case 5:
        return Step5;
      default:
        return null;
    }
  };

  return (
    <Slider {...settings}
            ref={slider => {
              sliderRef = slider;
            }}
    >
      {steps.map((step, index) => {
        return (
          <div key={index}>
            <div className={`slide slide${step.id}`}>
              <div className="container">
                <div className="row">
                  <div className="holder">
                    <div className="imgWrap col-md-3">
                      <img src={getIcon(index + 1)} alt="slider Image"/>
                    </div>
                    <div className="slider-content">
                      <div className="textWrap col-md-7">
                        <div className="title">How It Works</div>
                        <div className="contentArea">
                          <h4>{step.title}</h4>
                          <p>{step.description}</p>
                        </div>
                        <div className='slider-controls '>
                        <PrevArrow onClick={previous}/><NextArrow onClick={next}/>
                      </div>
                      </div>
                      
                    </div>
                    <div className="countWrap col-md-2">
                      <div className="countTitle">5 easy steps</div>
                      <div className="countNum">{step.id}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </Slider>
  );
}

export default HowItWorks;
