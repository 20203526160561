import React from "react";
import { Button } from "react-bootstrap";
import { SuccessImg } from "assets/images";
import { WhatsAppIconGreen } from "assets/icons";
import "pages/webOrder/_webOrder.scss";

function OrderSuccessFailure({ name, errorMsg, handleGoBack }) {
  return (
    <div>
      <div className="successWrap">
        <div className="content">
          <img src={SuccessImg}></img>
          {!errorMsg ? (
            <>
              <span className="header">Order Placed Successfully</span>
              <span className="subheading">Thank you for choosing {name}!</span>        
              <div className="text">
                <span>
                  Keep an eye on{" "}
                  <span className="whatsapp">WhatsApp</span>{" "}
                  <img 
                    src={WhatsAppIconGreen}
                    className="icon"
                  />
                  We’ll ping you soon with the total and delivery details.
                </span>
              </div>
            </>
          ) : (
            <div className="text">
              <span>{errorMsg}</span>
            </div>
          )}

          <Button
            style={{ width: "244px", height: "60px", fontWeight: "600" }}
            variant="primary"
            onClick={handleGoBack}
          >
            Explore Your Area
          </Button>
        </div>
      </div>
    </div>
  );
}

export default OrderSuccessFailure;

