import React from "react";
import "pages/privacy/_privacy.scss";

function Privacy() {
  return (
    <div className="privacyMain">
      <div className="sub">
        <p class="c31">
          <span class="c36">Fatafat</span>
          <a id="id.gjdgxs"></a>
          <span class="c21 c37">&nbsp;Privacy Policy</span>
        </p>
        <p class="c2" id="h.30j0zll">
          <span class="c1">Last modified: </span>
          <span class="c20">September</span>
          <span class="c1">&nbsp;</span>
          <span class="c20">5</span>
          <span class="c1">, 2024</span>
          <a id="id.1fob9te"></a>
        </p>
        <p class="c2">
          <span class="c7">Introduction</span>
        </p>
        <p class="c9" id="h.3znysh7">
          <span class="c1">At </span>
          <span class="c20">Fatafat</span>
          <span class="c1">&nbsp;(</span>
          <span class="c0">&ldquo;Company,&rdquo;</span>
          <span class="c1">&nbsp;&ldquo;</span>
          <span class="c0">Ourselves</span>
          <span class="c1">,&rdquo; &ldquo;</span>
          <span class="c0">We</span>
          <span class="c1">,&rdquo; &ldquo;</span>
          <span class="c0">Our</span>
          <span class="c1">
            ,&rdquo; and &ldquo;Us&rdquo;), we respect your privacy and are
            committed to protecting it through our compliance with this policy
            (&ldquo;
          </span>
          <span class="c0">Privacy Policy</span>
          <span class="c1">&rdquo;). In this Privacy Policy, &ldquo;</span>
          <span class="c0">You</span>
          <span class="c1">&rdquo; and &ldquo;</span>
          <span class="c0">Your</span>
          <span class="c1">
            &rdquo; refers to you, the person who uses this Website. This
            Privacy Policy does not cover practices of companies we do not own
            or control, or people we do not manage.
          </span>
        </p>
        <p class="c9">
          <span class="c1">
            This Privacy Policy describes the types of information we may
            collect from you or that you may provide when you visit the website
            https://
          </span>
          <span class="c20">fatafat.io</span>
          <span class="c1">&nbsp;(our &ldquo;</span>
          <span class="c0">Website</span>
          <span class="c1">
            &rdquo;) and our practices for collecting, using, maintaining,
            protecting, and disclosing that information.
          </span>
        </p>
        <p class="c2">
          <span class="c1">This policy applies to information we collect:</span>
        </p>
        <ul class="c10 lst-kix_list_19-0 start">
          <li class="c3 li-bullet-0">
            <span class="c1">On this Website;</span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              In email, text, and other electronic messages between you and this
              Website;
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Through mobile and desktop applications you download from this
              Website, which provide dedicated non-browser-based interaction
              between you and this Website;
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              When you interact with our advertising and applications on
              third-party websites and services, if those applications or
              advertising include links to this policy;
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Information provided to us by third-party companies (&ldquo;
            </span>
            <span class="c0">Clients</span>
            <span class="c1">&rdquo;) that engage our services; and</span>
          </li>
          <li class="c8 li-bullet-0">
            <span class="c1">
              Information provided to us by single sign-on third-parties and/or
              social networks you may use to create and manage an account with
              us, including but not limited to LinkedIn, Google, and Facebook
              (&ldquo;
            </span>
            <span class="c0">Single Sign-On Parties</span>
            <span class="c1">&rdquo;); </span>
          </li>
        </ul>
        <p class="c2">
          <span class="c1">It does not apply to information collected by:</span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c8 li-bullet-0">
            <span class="c1">
              Clients related to your possible engagement with them (employment
              or otherwise). Please refer to the respective Clients&rsquo;
              privacy policies on how they handle your personal information.
            </span>
          </li>
        </ul>
        <p class="c9">
          <span class="c1">
            Please read this policy carefully to understand our policies and
            practices regarding your information and how we will treat it. If
            you do not agree with our policies and practices, your choice is not
            to use our Website. By accessing or using this Website, you agree to
            this privacy policy. This policy may change from time to time (see{" "}
          </span>
          <span class="c1 c12">
            <a class="c5" href="#id.1ksv4uv">
              Changes to Our Privacy Policy
            </a>
          </span>
          <span class="c1">&nbsp;section below</span>
          <a id="id.2et92p0"></a>
          <span class="c1">
            ). Your continued use of this Website after we make changes is
            deemed to be acceptance of those changes, so please check the policy
            periodically for updates.{" "}
          </span>
        </p>
        <p class="c2">
          <span class="c7">Children Under the Age of 16</span>
        </p>
        <p class="c9">
          <span class="c1">
            Our Website is not intended for children under 16 years of age. No
            one under age 16 may provide any personal information to or on the
            Website. We do not knowingly collect personal information from
            children under 16. If you are under 16, do not use or provide any
            information on this Website or through any of its features, register
            on the Website, use any of the interactive features of this Website,
            or provide any information about yourself to us, including your
            name, address, telephone number, email address, or any screen name
            or user name you may use. If we learn we have collected or received
            personal information from a child under 16 without verification of
            parental consent, we will delete that information. If you believe we
            might have any information from or about a child under 16, please
            contact us at
          </span>
          <span class="c20">support@fatafat.io</span>
          <span class="c1">.</span>
        </p>
        <p class="c9" id="h.tyjcwt">
          <span class="c1">
            California residents under 16 years of age may have additional
            rights regarding the collection and sale of their personal
            information. Please{" "}
          </span>
          <span class="c20">contact us</span>
          <a id="id.3dy6vkm"></a>
          <span class="c1">&nbsp;</span>
          <span class="c1">for more information.</span>
        </p>
        <p class="c2">
          <span class="c7">
            Information We Collect About You and How We Collect It
          </span>
        </p>
        <p class="c9">
          <span class="c1">
            We collect several types of information from and about users of our
            Website, including information (&ldquo;
          </span>
          <span class="c0">personal information</span>
          <span class="c1">&rdquo;):</span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c8 li-bullet-0">
            <span class="c1">
              By which you may be personally identified, such as name, postal
              address, residential address, employment history, e-mail address,
              telephone number, social security number, unique personal
              identifier, online identifier, Internet Protocol address, account
              name, driver&rsquo;s license number, passport number, or any other
              identifier by which you may be contacted online or offline;
            </span>
          </li>
          <li class="c8 li-bullet-0">
            <span class="c1">
              That is about you but individually does not identify you; and/or
            </span>
          </li>
          <li class="c8 li-bullet-0">
            <span class="c1">
              About your internet connection, the equipment you use to access
              our Website, and usage details.
            </span>
          </li>
        </ul>
        <p class="c2">
          <span class="c1">We collect this information:</span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c3 li-bullet-0">
            <span class="c1">Directly from you when you provide it to us.</span>
          </li>
          <li class="c8 li-bullet-0">
            <span class="c1">
              Automatically as you navigate through the Website. Information
              collected automatically may include usage details, IP addresses,
              and information collected through cookies, web beacons, and other
              tracking technologies.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              From third parties, for example, our business partners,
              affiliates, advertising partners, Single Sign-On Parties, and
              Clients.
            </span>
            <a id="id.1t3h5sf"></a>
          </li>
        </ul>
        <p class="c2">
          <span class="c23 c20 c34">Information You Provide to Us</span>
          <span class="c0">&nbsp; </span>
        </p>
        <p class="c2">
          <span class="c1">
            The information we collect on or through our Website may include the
            following, which we may need to provide services to you:
          </span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c8 li-bullet-0">
            <span class="c1">
              Information that you provide by accessing and signing in filling
              in forms on our Website. This includes information provided at the
              time of registering to use our Website, subscribing to our
              service, or requesting further services. We may also ask you for
              information when you report a problem with our Website.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Records and copies of your correspondence (including email
              addresses), if you contact us.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Your responses to surveys that we might ask you to complete for
              research purposes.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">Your search queries on the Website.</span>
          </li>
        </ul>
        <p class="c9">
          <span class="c1">
            You also may provide information to be published or displayed
            (hereinafter, &ldquo;
          </span>
          <span class="c0">posted</span>
          <span class="c1">
            &rdquo;) on public areas of the Website, or transmitted to other
            users of the Website or third parties (collectively, &ldquo;
          </span>
          <span class="c0">User Contributions</span>
          <span class="c1">&rdquo;</span>
          <a id="id.4d34og8"></a>
          <span class="c1">
            ). Your User Contributions are posted on and transmitted to others
            at your own risk. Although we limit access to certain pages, please
            be aware that no security measures are perfect or impenetrable.
            Additionally, we cannot control the actions of other users of the
            Website with whom you may choose to share your User Contributions.
            Therefore, we cannot and do not guarantee that your User
            Contributions will not be viewed by unauthorized persons.
          </span>
        </p>
        <p class="c2">
          <span class="c23 c20 c34">
            Information We Collect Through Automatic Data Collection
            Technologies
          </span>
          <span class="c23 c21">&nbsp; </span>
        </p>
        <p class="c9">
          <span class="c1">
            As you navigate through and interact with our Website, we may use
            automatic data collection technologies and third-party services
            (Google Analytics, Yandex Matrica, Facebook Analytics, and other
            similar platforms) to collect certain information about you, your
            equipment, browsing actions, and patterns, including:
          </span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c3 li-bullet-0">
            <span class="c1">
              &nbsp;Details of your visits to our Website, including traffic
              data, location data, logs, and other communication data and the
              resources that you access and use on the Website.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Information about your computer and internet connection, including
              your IP address, operating system, and browser type.
            </span>
          </li>
        </ul>
        <p class="c9">
          <span class="c1">
            The information we collect automatically may include personal
            information. It helps us to improve our Website and to deliver a
            better and more personalized service, including by enabling us to:
          </span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c3 li-bullet-0">
            <span class="c1">
              Estimate our audience size and usage patterns.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Store information about your preferences, allowing us to customize
              our Website according to your individual interests.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">Speed up your searches.</span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Recognize you when you return to our Website.
            </span>
          </li>
        </ul>
        <p class="c2">
          <span class="c1">
            The technologies we use for this automatic data collection may
            include:
          </span>
        </p>
        <ul class="c10 lst-kix_list_42-0 start">
          <li class="c33 c17 li-bullet-0">
            <span class="c0">Cookies (or browser cookies).</span>
            <span class="c1">
              &nbsp;A cookie is a small file placed on the hard drive of your
              computer. The cookies we use are &ldquo;analytical&rdquo; cookies.
              Some of the common uses for our cookies are as follows: (i) to
              recognize and count the number of visitors and to see how visitors
              move around the site when they are using it. This helps us to
              improve the way our Website works, for example by ensuring that
              users are finding what they are looking for easily; (ii) to
              identify and authenticate a user across different pages of our
              Website, within our own Website, in a session or across different
              sessions. This is so that the user does not need to provide a
              password on every page the user visits; and (iii) to be able to
              retrieve a user&rsquo;s previously stored data, for example,
              information that the user previously submitted to the Website, so
              as to facilitate reuse of this information by the user. You may
              refuse to accept browser cookies by activating the appropriate
              setting on your browser. However, if you select this setting you
              may be unable to access certain or any parts of our Website, or
              use our services. Unless you have adjusted your browser setting so
              that it will refuse cookies, our system will issue cookies when
              you direct your browser to our Website.{" "}
            </span>
          </li>
        </ul>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c8 li-bullet-0">
            <span class="c0">Web Beacons.</span>
            <span class="c1">
              &nbsp;Pages of the Website and our e-mails may contain small
              electronic files known as web beacons (also referred to as clear
              gifs, pixel tags, and single-pixel gifs) that permit the Company,
              for example, to count users who have visited those pages or opened
              an email and for other related website statistics (for example,
              recording the popularity of certain website content and verifying
              system and server integrity).{" "}
            </span>
          </li>
          <li class="c8 li-bullet-0">
            <span class="c0">Pixels.</span>
            <span class="c1">
              &nbsp;A &ldquo;pixel&rdquo; or &ldquo;tag&rdquo; can be placed on
              a website or within an email for the purposes of tracking your
              interactions with our services or when emails from us are opened
              or accessed by email recipients. Pixels are often used in
              combination with cookies.
            </span>
          </li>
        </ul>
        <p class="c2">
          <span class="c1">
            We may tie this information to personal information about you that
            we collect from other sources or you provide to us. We will
            generally refer to cookies, web beacons, flash cookies, and pixels
            as &ldquo;cookies&rdquo; in this Policy.
          </span>
          <a id="id.2s8eyo1"></a>
        </p>
        <p class="c2">
          <span class="c7">
            Third-Party Use of Cookies and Other Tracking Technologies
          </span>
        </p>
        <p class="c9">
          <span class="c1">
            Some content or features, including advertisements, on the Website
            are served by third-parties, including advertisers, advertisement
            networks and servers, content providers, plug-ins, live chat
            platforms, database management, support and feedback platforms, user
            experience management, and application providers. These third
            parties may use cookies alone or in conjunction with web beacons or
            other tracking technologies to collect information about you when
            you use our website. The information they collect may be associated
            with your personal information or they may collect information,
            including personal information, about your online activities over
            time and across different websites and other online services. They
            may use this information to provide you with interest-based
            (behavioral) advertising or other targeted content.{" "}
          </span>
        </p>
        <p class="c9">
          <span class="c1">
            We do not control these third parties&rsquo; tracking technologies
            or how they may be used. If you have any questions about an
            advertisement or other targeted content, you should contact the
            responsible provider directly. For information about how you can opt
            out of receiving targeted advertising from many providers, see{" "}
          </span>
          <span class="c1 c12">
            <a class="c5" href="#id.26in1rg">
              Choices About How We Use and Disclose Your Information
            </a>
          </span>
          <span class="c1">.</span>
        </p>
        <p class="c2">
          <span class="c7">Cookie Duration</span>
        </p>
        <p class="c9">
          <span class="c1">
            The length of time a cookie will stay on your browsing device
            depends on whether it is a &ldquo;persistent&rdquo; or
            &ldquo;session&rdquo; cookie. Each category of cookie listed above
            is either a session cookie or persistent cookie.
          </span>
        </p>
        <p class="c9">
          <span class="c1">
            We use &ldquo;persistent cookies&rdquo; to save information about
            you for longer periods of time, such as your username and login
            password for future logins to the Website. We also use
            &ldquo;session cookies&rdquo; to collect and store information about
            you for short periods of time, such as information about your
            interactions with the Website. Session cookies are deleted after you
            close your browser.
          </span>
        </p>
        <p class="c9">
          <span class="c1">
            The retention period applicable to persistent cookies depends on the
            purpose of the cookie collection and tool used. You can delete
            cookie data as described below.
          </span>
        </p>
        <p class="c2">
          <span class="c7">Opting Out of Cookies</span>
        </p>
        <p class="c9">
          <span class="c1">
            As described in this Privacy Policy, we use third-party analytics
            providers to collect information about the effectiveness of our
            services and marketing campaigns. Some of the third parties that
            provide such cookies allow you to opt-out of their processing
            directly, as further described on their websites below:
          </span>
        </p>
        <ul class="c10 lst-kix_list_42-0">
          <li class="c9 c17 li-bullet-0">
            <span class="c1">Bing: http://choice.microsoft.com/</span>
          </li>
          <li class="c9 c17 li-bullet-0">
            <span class="c1">
              Google Analytics: https://tools.google.com/dlpage/gaoptout
              (requires you to install a browser add-on)
            </span>
          </li>
          <li class="c9 c17 li-bullet-0">
            <span class="c1">
              Hotjar: https://www.hotjar.com/legal/compliance/opt-out
            </span>
          </li>
          <li class="c9 c17 li-bullet-0">
            <span class="c1">
              Optimizely: https://www.optimizely.com/legal/opt-out/
            </span>
          </li>
          <li class="c9 c17 li-bullet-0">
            <span class="c1">
              Quantcast: https://www.quantcast.com/opt-out/
            </span>
          </li>
        </ul>
        <a id="id.17dp8vu"></a>
        <ul class="c10 lst-kix_list_42-0">
          <li class="c9 c17 li-bullet-0">
            <span class="c1">
              Segment.io: https://segment.com/docs/legal/privacy
            </span>
          </li>
        </ul>
        <p class="c2">
          <span class="c7">How We Use Your Information</span>
        </p>
        <p class="c2">
          <span class="c1">
            We do not sell, rent, or trade your personal information to third
            parties other than as disclosed in this Privacy Policy at the time
            you provide your information. We use information that we collect
            about you or that you provide to us, including any personal
            information:
          </span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c3 li-bullet-0">
            <span class="c1">
              To present our Website, its services, and its contents to you.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              To provide you with information or services that you request from
              us by sharing it with Clients and affiliates.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              To fulfill any other purpose for which you provide it.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              To notify you about changes to our Website or any products or
              services we offer or provide though it.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              To allow you to participate in interactive features on our
              Website.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              In any other way we may describe when you provide the information.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">For any other purpose with your consent.</span>
          </li>
        </ul>
        <p class="c2">
          <span class="c1">For more information, see </span>
          <span class="c1 c12">
            <a class="c5" href="#id.26in1rg">
              Choices About How We Use and Disclose Your Information
            </a>
          </span>
          <span class="c1">.</span>
        </p>
        <p class="c9">
          <span class="c1">
            Third party companies and individuals provide or facilitate certain
            aspects of the services offered through the Website on our behalf.
            We may provide these third-party service providers with access to
            your information, including your personal information, so that they
            can perform these services for us. We require these third-party
            service providers to provide safeguards for your personal
            information similar to the ones that we provide.
          </span>
        </p>
        <p class="c9">
          <span class="c1">
            The following are some examples of how we share your information
            with third-party service providers.
          </span>
        </p>
        <ul class="c10 lst-kix_list_42-0">
          <li class="c2 c17 li-bullet-0">
            <span class="c1">
              We retain third-party service providers to help us perform data
              analytics regarding your interactions with the Website. For
              example, Google Analytics may track what pages users visit on the
              Website, and what services users request to determine how users
              use the Website.
            </span>
          </li>
          <li class="c2 c17 li-bullet-0">
            <span class="c1">
              In some cases, these third-party service providers, may in turn
              share your personal information, such as your name and email
              address, with other third parties for their marketing-related
              purposes. If you wish to opt out of having your personal
              information shared with such third parties, please email
            </span>
            <span class="c20">&nbsp;</span>
            <span class="c20">support@fatafat.io</span>
            <span class="c1">.</span>
          </li>
        </ul>
        <a id="id.3rdcrjn"></a>
        <ul class="c10 lst-kix_list_42-0">
          <li class="c2 c17 li-bullet-0">
            <span class="c1">
              We may disclose your personal information to an attorney in the
              process of obtaining legal advice.
            </span>
          </li>
        </ul>
        <p class="c2">
          <span class="c7">Disclosure of Your Information</span>
        </p>
        <p class="c9">
          <span class="c1">
            We may disclose aggregated information about our users, and
            information that does not identify any individual, without
            restriction.{" "}
          </span>
        </p>
        <p class="c2">
          <span class="c1">
            We may disclose personal information that we collect or you provide
            as described in this privacy policy:
          </span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c3 li-bullet-0">
            <span class="c1">To our subsidiaries and affiliates.</span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              To contractors, service providers, and other third parties we use
              to support our business and who are bound by contractual
              obligations to keep personal information confidential and use it
              only for the purposes for which we disclose it to them.
            </span>
          </li>
          <li class="c8 li-bullet-0">
            <span class="c1">
              To a buyer or other successor in the event of a merger,
              divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of our assets, whether as a going
              concern or as part of bankruptcy, liquidation, or similar
              proceeding, in which personal information held by us about our
              Website users is among the assets transferred.
            </span>
          </li>
          <li class="c8 li-bullet-0">
            <span class="c1">
              To fulfill the purpose for which you provide it, including
              disclosing it to Clients and Single Sign-on Parties. For example,
              if you give us an email address to use the &ldquo;email a
              friend&rdquo; feature of our Website, we will transmit the
              contents of that email and your email address to the recipients.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              For any other purpose disclosed by us when you provide the
              information.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">With your consent.</span>
          </li>
        </ul>
        <p class="c2">
          <span class="c1">
            We may also disclose your personal information:
          </span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c3 li-bullet-0">
            <span class="c1">
              To comply with any court order, law, or legal process, including
              to respond to any government or regulatory request.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">To enforce or apply our terms of use </span>
            <span class="c28">
              <a
                class="c5"
                href="https://www.google.com/url?q=https://hirestream.io/terms-and-conditions&amp;sa=D&amp;source=editors&amp;ust=1630942815181000&amp;usg=AOvVaw2jaDeHifXUeA0b0SJOVzvi"
              >
                https://fatafat.io/terms-and-conditions
              </a>
            </span>
            <span class="c1">
              &nbsp;and other agreements, including for billing and collection
              purposes.
            </span>
          </li>
          <li class="c8 li-bullet-0">
            <span class="c1">
              If we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of the Company, our customers, or
              others. This includes exchanging information with other companies
              and organizations for the purposes of fraud protection and credit
              risk reduction.
            </span>
          </li>
        </ul>
        <a id="id.26in1rg"></a>
        <p class="c29 c27 c33">
          <span class="c1"></span>
        </p>
        <p class="c2">
          <span class="c7">
            Choices About How We Use and Disclose Your Information
          </span>
        </p>
        <p class="c9">
          <span class="c1">
            We strive to provide you with choices regarding the personal
            information you provide to us. We have created mechanisms to provide
            you with the following control over your information:{" "}
          </span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c8 li-bullet-0">
            <span class="c0">Tracking Technologies and Advertising.</span>
            <span class="c1">
              &nbsp;You can set your browser to refuse all or some browser
              cookies, or to alert you when cookies are being sent. You can also
              set your preferences within the cookie notice or by updating such
              preferences accordingly via the relevant consent-preferences
              widget, if available. It is also possible, via relevant browser or
              device features, to delete previously stored cookies, including
              those used to remember your initial consent. Other cookies in the
              browser&rsquo;s local memory may be cleared by deleting the
              browsing history. If you disable or refuse cookies, please note
              that some parts of this site may then be inaccessible or not
              function properly.
            </span>
          </li>
          <li class="c8 li-bullet-0">
            <span class="c0">Offers from the Company.</span>
            <span class="c1">
              &nbsp;If you do not wish to have your email address/contact
              information used by the Company to promote our own or third
              parties&rsquo; products or services, you can opt-out by{" "}
            </span>
            <span class="c1">sending us an email stating your request to </span>
            <span class="c20 c40">
              <a class="c5" href="mailto:support@fatafat.io">
                support@fatafat.io
              </a>
            </span>
            <span class="c20 c38">&nbsp;</span>
            <span class="c20">subject &ldquo;OPT-OUT&rdquo;</span>
            <span class="c1">.</span>
            <span class="c1">
              &nbsp;If we have sent you a promotional email, you may send us a
              return email asking to be omitted from future email distributions.{" "}
            </span>
          </li>
        </ul>
        <p class="c9">
          <span class="c1">
            We do not control third parties&rsquo; collection or use of your
            information to serve interest-based advertising. However, these
            third parties may provide you with ways to choose not to have your
            information collected or used in this way. You can opt out of
            receiving targeted ads from members of the Network Advertising
            Initiative (&ldquo;
          </span>
          <span class="c0">NAI</span>
          <span class="c1">&rdquo;) on the NAI&rsquo;s website.</span>
        </p>
        <p class="c9">
          <span class="c1">
            California residents may have additional personal information rights
            and choices. Please see{" "}
          </span>
          <span class="c1">
            <a class="c5" href="#id.35nkun2">
              [Your California Privacy Rights]
            </a>
          </span>
          <span class="c1">&nbsp;for more information.</span>
        </p>
        <p class="c9">
          <span class="c1">
            If you are a resident of Nevada, you have the right to opt-out of
            the sale of certain personal information to third parties who intend
            to license or sell that personal information. Nevada residents who
            wish to exercise their sale opt-out rights under Nevada Revised
            Statutes Chapter 603A may submit a request to this designated
            address:
          </span>
          <span class="c20">&nbsp;support@fatafat.io</span>
          <span class="c1">
            &nbsp;with the subject line &ldquo;Nevada Do Not Sell Request&rdquo;
            and providing us with your name and the email address associated
            with your account, or write us at:{" "}
          </span>
          <span class="c20">
            Fatafat; 25-Westwood Colony, Thokar Niaz Baig Lahore, PK 53700
          </span>
          <span class="c1">
            &nbsp;However, please know we do not currently sell data triggering
            that statute&rsquo;s opt-out requirements.
          </span>
          <a id="id.lnxbz9"></a>
        </p>
        <p class="c2">
          <span class="c7">Accessing and Correcting Your Information</span>
        </p>
        <p class="c9">
          <span class="c1">
            You can review and change your personal information by logging into
            the Website and visiting your account profile page.{" "}
          </span>
        </p>
        <p class="c9">
          <span class="c1">
            You may also send us an email at support@fatafat.io to request
            access to, correct, or delete any personal information that you have
            provided to us. We cannot delete your personal information except by
            also deleting your user account. We may not accommodate a request to
            change information if we believe the change would violate any law or
            legal requirement or cause the information to be incorrect.
          </span>
        </p>
        <p class="c9">
          <span class="c1">
            If you delete your User Contributions from the Website, copies of
            your User Contributions may remain viewable in cached and archived
            pages, or might have been copied or stored by other Website users.
            Proper access and use of information provided on the Website,
            including User Contributions, is governed by our terms of use
          </span>
          <span class="c20">&nbsp;</span>
          <span class="c28">
            <a
              class="c5"
              href="https://www.google.com/url?q=https://hirestream.io/terms-and-conditions&amp;sa=D&amp;source=editors&amp;ust=1630942815183000&amp;usg=AOvVaw1da8aCrkCrt-bdVJhw_AAJ"
            >
              https://fatafat.io/terms-and-conditions
            </a>
          </span>
          <span class="c1">.</span>
        </p>
        <p class="c2">
          <span class="c1">
            California residents may have additional personal information rights
            and choices. Please see{" "}
          </span>
          <span class="c1 c12">
            <a class="c5" href="#id.35nkun2">
              [Your California Privacy Rights]
            </a>
          </span>
          <a id="id.35nkun2"></a>
          <span class="c1">&nbsp;for more information.</span>
        </p>
        <p class="c2">
          <span class="c7">Data Security</span>
        </p>
        <p class="c9">
          <span class="c1">
            We have implemented measures designed to secure your personal
            information from accidental loss and from unauthorized access, use,
            alteration, and disclosure. All information you provide to us is
            stored on our secure servers behind firewalls. The safety and
            security of your information also depends on you. Where we have
            given you (or where you have chosen) a password for access to
            certain parts of our Website, you are responsible for keeping this
            password confidential. We ask you not to share your password with
            anyone. We urge you to be careful about giving out information in
            public areas of the Website like message boards. The information you
            share in public areas may be viewed by any user of the Website.
          </span>
        </p>
        <p class="c9">
          <span class="c1">
            Unfortunately, the transmission of information via the internet is
            not completely secure. Although we do our best to protect your
            personal information, we cannot guarantee the security of your
            personal information transmitted to our Website. Any transmission of
            personal information is at your own risk. We are not responsible for
            circumvention of any privacy settings or security measures contained
            on the Website.
          </span>
        </p>
        <p class="c2">
          <span class="c7">Username, Password and Access to Your Account</span>
        </p>
        <p class="c9">
          <span class="c1">
            You are solely responsible for managing your username and password
            and for keeping your password confidential. No one from the Company
            will be authorized to provide you your password. If you forget your
            username or password, click on the &ldquo;Forgot Your
            Password&rdquo; link, or contact us via the Contact Us section of
            the Site to request your user name or password. For your privacy, we
            do not have access to your password information. However, we can
            reset it for you so that you can update your account with a new
            password. You are also solely responsible for restricting access to
            any computer or mobile device you use to access your account on the
            Website. You agree that you are responsible for all activities that
            occur on your account or through use of your password by yourself or
            by other persons.{" "}
          </span>
        </p>
        <a id="id.1ksv4uv"></a>
        <p class="c9">
          <span class="c1">
            If we provide you with, and you opt for a &ldquo;Remember me&rdquo;
            feature for your account, or if you login with Single-Sign Parties,
            a cookie helps us recall certain items of your specific information
            on subsequent visits, including your username and password. When you
            return to your account, the information you previously provided can
            be retrieved, so that it is not necessary to enter your username and
            password to access certain functionality. While cookies are a
            convenience, please remember that if you elect the &ldquo;Remember
            Me&rdquo; option, anyone who can access the computer or mobile
            device you are using will have the ability to access your account
            for certain functions and view certain information about your
            account.
          </span>
        </p>
        <p class="c2">
          <span class="c7">Changes to Our Privacy Policy</span>
        </p>
        <p class="c9">
          <span class="c1">
            It is our policy to post any changes we make to our privacy policy
            on this page with a notice that the privacy policy has been updated
            on the Website home page. If we make material changes to how we
            treat our users&rsquo;
          </span>
          <a id="id.44sinio"></a>
          <span class="c1">
            &nbsp;personal information, we will notify you through a notice on
            the Website home page or by email. The date the privacy policy was
            last revised is identified at the top of the page. You are
            responsible for ensuring we have an up-to-date active and
            deliverable email address for you, and for periodically visiting our
            Website and this privacy policy to check for any changes.
          </span>
        </p>
        <p class="c2">
          <span class="c7">Contact Information</span>
        </p>
        <p class="c2">
          <span class="c1">
            To ask questions or comment about this privacy policy and our
            privacy practices, contact us at:{" "}
          </span>
        </p>
        <p class="c2">
          <span class="c20">Fatafat; </span>
          <span class="c1">
            25-Westwood Colony, Thokar Niaz Baig Lahore, PK 53700
          </span>
        </p>
        <p class="c2">
          <span class="c1">Owner contact email:</span>
          <span class="c20">&nbsp;support@fatafat.io</span>
        </p>
        <p class="c2">
          <span class="c7">GDPR</span>
        </p>
        <p class="c9">
          <span class="c20 c34">
            All users of the Website shall comply with all applicable data
            protection and privacy laws and regulations in the performance of
            its obligations set out under these Terms, including the European
            Union General Data Protection Regulation 2016/679 (&ldquo;
          </span>
          <span class="c21">GDPR</span>
          <span class="c1">
            &rdquo;), in each case including all other successor legislation and
            regulation thereto.
          </span>
        </p>
        <p class="c9 c27">
          <span class="c1 c18"></span>
        </p>
        <p class="c2">
          <span class="c7">Your California Privacy Rights</span>
        </p>
        <p class="c2">
          <span class="c1">
            If you are a California resident, California law may provide you
            with additional rights regarding our use of your personal
            information.{" "}
          </span>
        </p>
        <p class="c9">
          <span class="c1">
            California&rsquo;s &ldquo;Shine the Light&rdquo; law (Civil Code
            Section &sect; 1798.83) permits users of our Website that are
            California residents to request certain information regarding our
            disclosure of personal information to third parties for their direct
            marketing purposes. To make such a request, please send an email to
          </span>
          <span class="c20">&nbsp;support@fatafat.io</span>
          <span class="c1">&nbsp;or write us at: </span>
          <span class="c1">
            Fatafat 25-Westwood Colony, Thokar Niaz Baig Lahore, PK 53700
          </span>
        </p>
        <p class="c9 c27">
          <span class="c1"></span>
        </p>
        <p class="c9">
          <span class="c1">
            The California Consumer Privacy Act of 2018 (&ldquo;CCPA&rdquo;)
            imposes specific obligations on businesses processing personal
            information of California residents. Pursuant to the CCPA, we are a
            &ldquo;Business&rdquo; (As defined by the CCPA) and are required to
            provide its visitors, users, and others who reside in the State of
            California (&ldquo;California Persons&rdquo;) a notice at or before
            the point of collection of such personal information, that
            identifies the categories of personal information that may be
            collected and why we collect such information. For purposes of this
            Section, personal information does not include:
          </span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c3 li-bullet-0">
            <span class="c1">
              Publicly available information from government records;
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Deidentified or aggregated consumer information;
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Information excluded from the CCPA&rsquo;s scope, like:
            </span>
          </li>
        </ul>
        <ul class="c10 lst-kix_list_19-1 start">
          <li class="c17 c24 li-bullet-0">
            <span class="c1">
              health or medical information covered by the Health Insurance
              Portability and Accountability Act of 1996 (HIPAA) and the
              California Confidentiality of Medical Information Act (CMIA),
              clinical trial data, or other qualifying research data;
            </span>
          </li>
          <li class="c24 c17 li-bullet-0">
            <span class="c1">
              personal information covered by certain sector-specific privacy
              laws, including the Fair Credit Reporting Act (FCRA), the
              Gramm-Leach-Bliley Act (GLBA) or California Financial Information
              Privacy Act (FIPA), and the Driver&rsquo;s Privacy Protection Act
              of 1994.{" "}
            </span>
          </li>
        </ul>
        <p class="c9">
          <span class="c1">
            Where noted in this Section, the CCPA temporarily exempts personal
            information reflecting a written or verbal business-to-business
            communication (&ldquo;
          </span>
          <span class="c0">B2B personal information</span>
          <span class="c1">&rdquo;) from some its requirements.</span>
        </p>
        <p class="c9">
          <span class="c1">
            In particular, our Website has collected the following categories of
            personal information from consumers within the last twelve (12)
            months:{" "}
          </span>
        </p>
        <p class="c2 c27">
          <span class="c1"></span>
        </p>
        <a id="t.3aebe969f1ce74782ae2c44858f98eb8b812fe14"></a>
        <a id="t.0"></a>
        <table class="c25">
          <tbody>
            <tr class="c11">
              <td class="c6" colspan="1" rowspan="1">
                <p class="c14">
                  <span class="c0">Category</span>
                </p>
              </td>
              <td class="c19" colspan="1" rowspan="1">
                <p class="c14">
                  <span class="c0">Examples</span>
                </p>
              </td>
              <td class="c6" colspan="1" rowspan="1">
                <p class="c14">
                  <span class="c0">Collected</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">A. Identifiers.</span>
                </p>
              </td>
              <td class="c19" colspan="1" rowspan="1">
                <p class="c2" id="h.2jxsxqh">
                  <span class="c1">
                    A real name, alias, postal address, unique personal
                    identifier, online identifier, Internet Protocol address,
                    email address, account name, Social Security number,
                    driver&rsquo;s license number, passport number, or other
                    similar identifiers.
                  </span>
                </p>
              </td>
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">YES</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    B. Personal information categories listed in the California
                    Customer Records statute (Cal. Civ. Code &sect; 1798.80(e)).
                  </span>
                </p>
              </td>
              <td class="c19" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    A name, signature, Social Security number, physical
                    characteristics or description, address, telephone number,
                    passport number, driver&rsquo;s license or state
                    identification card number, insurance policy number,
                    education, employment, employment history, bank account
                    number, credit card number, debit card number, or any other
                    financial information, medical information, or health
                    insurance information.
                  </span>
                </p>
                <p class="c2">
                  <span class="c1">
                    Some personal information included in this category may
                    overlap with other categories.
                  </span>
                </p>
              </td>
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c20">NO</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    C. Protected classification characteristics under California
                    or federal law.
                  </span>
                </p>
              </td>
              <td class="c19" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    Age (40 years or older), race, color, ancestry, national
                    origin, citizenship, religion or creed, marital status,
                    medical condition, physical or mental disability, sex
                    (including gender, gender identity, gender expression,
                    pregnancy or childbirth and related medical conditions),
                    sexual orientation, veteran or military status, genetic
                    information (including familial genetic information).
                  </span>
                </p>
                <p class="c2 c27">
                  <span class="c1"></span>
                </p>
              </td>
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c20">NO</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">D. Commercial information.</span>
                </p>
              </td>
              <td class="c19" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    Records of personal property, products or services
                    purchased, obtained, or considered, or other purchasing or
                    consuming histories or tendencies.
                  </span>
                </p>
              </td>
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">NO</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">E. Biometric information.</span>
                </p>
              </td>
              <td class="c19" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    Genetic, physiological, behavioral, and biological
                    characteristics, or activity patterns used to extract a
                    template or other identifier or identifying information,
                    such as, fingerprints, faceprints, and voiceprints, iris or
                    retina scans, keystroke, gait, or other physical patterns,
                    and sleep, health, or exercise data.
                  </span>
                </p>
              </td>
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">NO</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    F. Internet or other similar network activity.
                  </span>
                </p>
              </td>
              <td class="c19" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    Browsing history, search history, information on a
                    consumer&rsquo;s interaction with a website, application, or
                    advertisement.
                  </span>
                </p>
              </td>
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">YES</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">G. Geolocation data.</span>
                </p>
              </td>
              <td class="c19" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">Physical location or movements. </span>
                </p>
              </td>
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">NO</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">H. Sensory data.</span>
                </p>
              </td>
              <td class="c19" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    Audio, electronic, visual, thermal, olfactory, or similar
                    information.
                  </span>
                </p>
              </td>
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c20">YES</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    I. Professional or employment-related information.
                  </span>
                </p>
              </td>
              <td class="c19" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    Current or past job history or performance evaluations.
                  </span>
                </p>
              </td>
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c20">NO</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    J. Non-public education information (per the Family
                    Educational Rights and Privacy Act (20 U.S.C. Section 1232g,
                    34 C.F.R. Part 99)).
                  </span>
                </p>
              </td>
              <td class="c19" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    Education records directly related to a student maintained
                    by an educational institution or party acting on its behalf,
                    such as grades, transcripts, class lists, student schedules,
                    student identification codes, student financial information,
                    or student disciplinary records.{" "}
                  </span>
                </p>
              </td>
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c20">NO</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    K. Inferences drawn from other personal information.
                  </span>
                </p>
              </td>
              <td class="c19" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    Profile reflecting a person&rsquo;s preferences,
                    characteristics, psychological trends, predispositions,
                    behavior, attitudes, intelligence, abilities, and aptitudes.
                  </span>
                </p>
              </td>
              <td class="c6" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">YES</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p class="c2">
          <span class="c1">
            We obtain the categories of personal information listed above from
            the following categories of sources:
          </span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c3 li-bullet-0">
            <span class="c1">
              Directly from you. For example, from forms you complete or
              services you request from us.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Indirectly from you. For example, from observing your actions on
              our Website.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              From Single Sign-On Parties or Clients as related to the services
              provided on our Website.
            </span>
            <a id="id.z337ya"></a>
          </li>
        </ul>
        <p class="c14">
          <span class="c7">Sharing Personal Information</span>
        </p>
        <p class="c9">
          <span class="c1">
            We may share your personal information by disclosing it to a third
            party for a business purpose, specifically to our Clients. We only
            make these business purpose disclosures under written contracts that
            describe the purposes, require the recipient to keep the personal
            information confidential, and prohibit using the disclosed
            information for any purpose except performing the contract. In the
            preceding twelve (12) months, Company has disclosed personal
            information for a business purpose to the categories of third
            parties indicated in the chart below. We do not sell personal
            information.{" "}
          </span>
        </p>
        <p class="c2 c27">
          <span class="c1"></span>
        </p>
        <a id="t.bdf62d89847126ebbf3191cb990ce386315209f0"></a>
        <a id="t.1"></a>
        <table class="c30">
          <tbody>
            <tr class="c35">
              <td class="c13" colspan="1" rowspan="2">
                <p class="c14">
                  <span class="c0">Personal Information Category</span>
                </p>
              </td>
              <td class="c39" colspan="2" rowspan="1">
                <p class="c14">
                  <span class="c0">Category of Third-Party Recipients</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c15" colspan="1" rowspan="1">
                <p class="c14">
                  <span class="c0">Business Purpose Disclosures</span>
                </p>
              </td>
              <td class="c16" colspan="1" rowspan="1">
                <p class="c14">
                  <span class="c0">Sales</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c13" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">A: Identifiers.</span>
                </p>
              </td>
              <td class="c15" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">Single Sign-On Parties and/or Clients</span>
                </p>
              </td>
              <td class="c16" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">None</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c13" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    B: California Customer Records personal information
                    categories.
                  </span>
                </p>
              </td>
              <td class="c15" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">Single Sign-On Parties and/or Clients</span>
                </p>
              </td>
              <td class="c16" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">None</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c13" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    C: Protected classification characteristics under California
                    or federal law.
                  </span>
                </p>
              </td>
              <td class="c15" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">Single Sign-On Parties and/or Clients</span>
                </p>
              </td>
              <td class="c16" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">None</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c13" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">D: Commercial information.</span>
                </p>
              </td>
              <td class="c15" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">Single Sign-On Parties and/or Clients</span>
                </p>
              </td>
              <td class="c16" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">None</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c13" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">E: Biometric information.</span>
                </p>
              </td>
              <td class="c15" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">Single Sign-On Parties and/or Clients</span>
                </p>
              </td>
              <td class="c16" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">None</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c13" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    F: Internet or other similar network activity.
                  </span>
                </p>
              </td>
              <td class="c15" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">Single Sign-On Parties and/or Clients</span>
                </p>
              </td>
              <td class="c16" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">None</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c13" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">G: Geolocation data.</span>
                </p>
              </td>
              <td class="c15" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">Single Sign-On Parties and/or Clients</span>
                </p>
              </td>
              <td class="c16" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">None</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c13" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">H: Sensory data.</span>
                </p>
              </td>
              <td class="c15" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">Single Sign-On Parties and/or Clients</span>
                </p>
              </td>
              <td class="c16" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">None</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c13" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    I: Professional or employment-related information.
                  </span>
                </p>
              </td>
              <td class="c15" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">Single Sign-On Parties and/or Clients</span>
                </p>
              </td>
              <td class="c16" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">None</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c13" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">J: Non-public education information.</span>
                </p>
              </td>
              <td class="c15" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">Single Sign-On Parties and/or Clients</span>
                </p>
              </td>
              <td class="c16" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">None</span>
                </p>
              </td>
            </tr>
            <tr class="c11">
              <td class="c13" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">
                    K: Inferences drawn from other personal information.
                  </span>
                </p>
              </td>
              <td class="c15" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">Single Sign-On Parties and/or Clients</span>
                </p>
              </td>
              <td class="c16" colspan="1" rowspan="1">
                <p class="c2">
                  <span class="c1">None</span>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <a id="id.3j2qqm3"></a>
        <p class="c14">
          <span class="c7">Your Rights and Choices </span>
        </p>
        <p class="c9">
          <span class="c1">
            The CCPA provides consumers (California residents) with specific
            rights regarding their personal information. This section describes
            your CCPA rights and explains how to exercise those rights.
          </span>
          <a id="id.1y810tw"></a>
        </p>
        <p class="c2">
          <span class="c23 c21">Right to Know and Data Portability</span>
        </p>
        <p class="c2">
          <span class="c1">
            You have the right to request that we disclose certain information
            to you about our collection and use of your personal information
            over the past 12 months (the &ldquo;right to know&rdquo;). Once we
            receive your request and confirm your identity (see{" "}
          </span>
          <span class="c1 c12">
            <a class="c5" href="#id.2xcytpi">
              Exercising Your Rights to Know or Delete
            </a>
          </span>
          <span class="c1">), we will disclose to you:</span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c3 li-bullet-0">
            <span class="c1">
              The categories of personal information we collected about you.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              The categories of sources for the personal information we
              collected about you.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Our business or commercial purpose for collecting or selling that
              personal information.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              The categories of third parties with whom we share that personal
              information.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              The specific pieces of personal information we collected about you
              (also called a data portability request).
            </span>
          </li>
        </ul>
        <p class="c2">
          <span class="c1">
            &nbsp;We do not provide a right to know or data portability
            disclosure for B2B personal information.
          </span>
          <a id="id.4i7ojhp"></a>
        </p>
        <p class="c2">
          <span class="c23 c21">Right to Delete </span>
        </p>
        <p class="c9">
          <span class="c1">
            You have the right to request that we delete any of your personal
            information that we collected from you and retained, subject to
            certain exceptions (the &ldquo;right to delete&rdquo;). Once we
            receive your request and confirm your identity (see{" "}
          </span>
          <span class="c1 c12">
            <a class="c5" href="#id.2xcytpi">
              Exercising Your Rights to Know or Delete
            </a>
          </span>
          <span class="c1">
            ), we will review your request to see if an exception allowing us to
            retain the information applies. We may deny your deletion request if
            retaining the information is necessary for us or our service
            provider(s) to:{" "}
          </span>
        </p>
        <ol class="c10 lst-kix_list_17-0 start" start="1">
          <li class="c4 li-bullet-1">
            <span class="c1">
              Complete the transaction for which we collected the personal
              information, provide a good or service that you requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with you, fulfill the terms of a written
              warranty or product recall conducted in accordance with federal
              law, or otherwise perform our contract with you.
            </span>
          </li>
          <li class="c4 li-bullet-1">
            <span class="c1">
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities.
            </span>
          </li>
          <li class="c4 li-bullet-2">
            <span class="c1">
              Debug products to identify and repair errors that impair existing
              intended functionality.
            </span>
          </li>
          <li class="c4 li-bullet-1">
            <span class="c1">
              Exercise free speech, ensure the right of another consumer to
              exercise their free speech rights, or exercise another right
              provided for by law.
            </span>
          </li>
          <li class="c4 li-bullet-1">
            <span class="c1">
              Comply with the California Electronic Communications Privacy Act
              (Cal. Penal Code &sect; 1546{" "}
            </span>
            <span class="c23 c20 c34">et. seq.</span>
            <span class="c1">).</span>
          </li>
          <li class="c4 li-bullet-1">
            <span class="c1">
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when the
              information&rsquo;s deletion may likely render impossible or
              seriously impair the research&rsquo;s achievement, if you
              previously provided informed consent.
            </span>
          </li>
          <li class="c4 li-bullet-1">
            <span class="c1">
              Enable solely internal uses that are reasonably aligned with
              consumer expectations based on your relationship with us.
            </span>
          </li>
          <li class="c4 li-bullet-1">
            <span class="c1">Comply with a legal obligation.</span>
          </li>
          <li class="c4 li-bullet-1">
            <span class="c1">
              Make other internal and lawful uses of that information that are
              compatible with the context in which you provided it.
            </span>
          </li>
        </ol>
        <p class="c2">
          <span class="c1">
            We will delete or deidentify personal information not subject to one
            of these exceptions from our records and will direct our service
            providers to take similar action.{" "}
          </span>
        </p>
        <p class="c2">
          <span class="c1">
            We do not provide these deletion rights for B2B personal
            information.
          </span>
          <a id="id.2xcytpi"></a>
        </p>
        <p class="c2">
          <span class="c21 c23">Exercising Your Rights to Know or Delete</span>
        </p>
        <p class="c2">
          <span class="c1">
            To exercise your rights to know or delete described above, please
            submit a request by either writing to us or sending us an email:{" "}
          </span>
        </p>
        <p class="c2">
          <span class="c20">Fatafat ...</span>
          <span class="c1">&nbsp;</span>
        </p>
        <p class="c2">
          <span class="c1">
            25-Westwood Colony, Thokar Niaz Baig Lahore, PK 53700
          </span>
        </p>
        <p class="c2">
          <span class="c20">support@fatafat.io</span>
        </p>
        <p class="c2">
          <span class="c1">
            Only you, or someone legally authorized to act on your behalf, may
            make a request to know or delete related to your personal
            information.
          </span>
        </p>
        <p class="c2">
          <span class="c1">
            You may only submit a request to know twice within a 12-month
            period. Your request to know or delete must:
          </span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c8 li-bullet-0">
            <span class="c1">
              Provide sufficient information that allows us to reasonably verify
              you are the person about whom we collected personal information or
              an authorized representative, which may include: government issued
              identification, address, social security number, and any other
              personal information we need to verify your identity against the
              information in our records;
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Describe your request with sufficient detail that allows us to
              properly understand, evaluate, and respond to it.
            </span>
          </li>
        </ul>
        <p class="c9">
          <span class="c1">
            We cannot respond to your request or provide you with personal
            information if we cannot verify your identity or authority to make
            the request and confirm the personal information relates to you.{" "}
          </span>
        </p>
        <p class="c2">
          <span class="c1">
            You do not need to create an account with us to submit a request to
            know or delete. We will only use personal information provided in
            the request to verify the requestor&rsquo;s identity or authority to
            make it.{" "}
          </span>
          <a id="id.1ci93xb"></a>
        </p>
        <p class="c14">
          <span class="c7">Non-Discrimination</span>
        </p>
        <p class="c2">
          <span class="c1">
            We will not discriminate against you for exercising any of your CCPA
            rights. Unless permitted by the CCPA, we will not:
          </span>
        </p>
        <ul class="c10 lst-kix_list_19-0">
          <li class="c3 li-bullet-0">
            <span class="c1">Deny you goods or services.</span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Charge you different prices or rates for goods or services,
              including through granting discounts or other benefits, or
              imposing penalties.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Provide you a different level or quality of goods or services.
            </span>
          </li>
          <li class="c3 li-bullet-0">
            <span class="c1">
              Suggest that you may receive a different price or rate for goods
              or services or a different level or quality of goods or services.
            </span>
          </li>
        </ul>
        <p class="c24 c27 c29">
          <span class="c1"></span>
        </p>
        <div>
          <p class="c14 c27">
            <span class="c22"></span>
          </p>
          <p class="c2">
            <span class="c22">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
