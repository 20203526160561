import React from 'react';
import { Link } from "react-router-dom";

function ShopMenus({menu}) {
  return menu?.length > 0 ?
    <div className="input-wrapper" id="menu-section">
      <p>Shop Menu</p>
      <div className="menu" id="menu">
        {
          menu.map((item, index) => {
            return <Link key={index} to={item.link || item.image_url} target="_blank">{`Menu${index + 1}`}</Link>
          })
        }
      </div>
    </div>
    : null;
}

export default ShopMenus;