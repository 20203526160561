import React, { useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import Carousel  from "react-multi-carousel";
import GalleryModal from "../../modals/galleryModal/GalleryModal";
import { hooks } from 'common';

function MenuCarousel({menuItems, name}) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [showGallery, setShowGallery] = useState(false);
  const images = menuItems ? menuItems.filter(item => item.image_url) : [];

  const responsive = {
    desktop: {
      breakpoint: {max: 3000, min: 1024},
      items: 5,
    },
    tablet: {
      breakpoint: {max: 1024, min: 768},
      items: 3,
    },
    mobile: {
      breakpoint: {max: 767, min: 350},
      items: 2,
    }
  };

  const openGallery = (index) => {
    setActiveIndex(index)
    setShowGallery(true);
  }

  hooks.useCloseModalOnBack(showGallery, setShowGallery);

  return (
    <>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        infinite={true}
        partialVisible={false}
        keyBoardControl={true}
        id="carouselContainer"
        containerClass="carouselWrap"
        itemClass="carousel"
      >
          {
            images?.map((src, index) => {
              return <li key={index} className="slide">
                <div onClick={() => openGallery(index)}>
                  <img src={src.image_url} alt=""/>
                </div>
              </li>
            })
          }
      </Carousel>
      <GalleryModal activeIndex={activeIndex} setActiveIndex={setActiveIndex} images={images} show={showGallery}
                    setShow={setShowGallery} name={name}/>
    </>
  )
}

export default MenuCarousel;