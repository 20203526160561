import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import generalExtraActions from "../actions/general";
import { utils } from "../../common";

const INITIAL_STATE = {
  loading: false,
  orderShopsLoading: false,
  error: null,
  areas: null,
  shops: [],
  shopStatus: null,
  nextPage: null,
  orderShops: [],
  shopMapByCategory: {},
  categories: [],
  orderToken: null,
  reviews: null,
  currentShop: null,
  currentShopLoading: false,
  permission: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: INITIAL_STATE,
  reducers: {
    resetGeneralSlice: () => INITIAL_STATE,
    resetReviews: (state) => {
      state.reviews = null;
    },
    resetCurrentShop: (state) => {
      state.currentShop = null;
    },
    setPermission: (state, action) => {
      state.permission = action.payload;
    },
    resetPermission: (state) => {
      state.permission = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generalExtraActions.getAreas.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.areas = payload
    });
    builder.addCase(generalExtraActions.getCategories.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.categories = payload;
    });
    builder.addCase(generalExtraActions.getCategories.pending, (state, {payload}) => {
      state.loading = true;
      state.categories = [];
    });
    builder.addCase(generalExtraActions.getSearchCategories.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.categories = payload;
      payload.forEach((categoryPayload, index) => {
        if(state.categories[index]) {
          state.categories[index].shop_count = categoryPayload.shop_count;
        }
      });
    });
    builder.addCase(generalExtraActions.getShops.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.shops = [...state.shops, ...payload.results];
      state.nextPage = utils.getParamFromUrl(payload.next, 'page')
    });
    builder.addCase(generalExtraActions.getShopsFromStart.pending, (state, {payload}) => {
      state.loading = true;
      state.shops = [];
      state.shopStatus = "pending";
      state.nextPage = null;
      state.error = null;
    });
    builder.addCase(generalExtraActions.getShopsFromStart.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.shops = payload.results;
      state.nextPage = utils.getParamFromUrl(payload.next, 'page')
      state.shopStatus = "success";
    });
    builder.addCase(generalExtraActions.getOrderShops.fulfilled, (state, {payload}) => {
      state.orderShopsLoading = false;
      state.orderShops = payload;
    });
    builder.addCase(generalExtraActions.createCustomerOrder.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.orderToken = payload.link.split('=').slice(-1);
    });
    builder.addCase(generalExtraActions.getCustomerReviews.fulfilled, (state, {payload}) => {
      state.loading = false;
      state.reviews = payload;
    });
    builder.addCase(generalExtraActions.createCustomerReview.fulfilled, (state, {payload}) => {
      state.loading = false;
    });
    builder.addCase(generalExtraActions.getShopDetail.pending, (state, {payload}) => {
      state.currentShopLoading = true;
      state.currentShop = null;
    });
    builder.addCase(generalExtraActions.getShopDetail.fulfilled, (state, {payload}) => {
      state.currentShopLoading = false;
      state.currentShop = payload;
    });
    builder.addCase(generalExtraActions.getShopDetail.rejected, (state, {payload}) => {
      state.currentShopLoading = false;
    });
    builder.addCase(generalExtraActions.getOrderShops.pending, (state, {payload}) => {
      state.orderShopsLoading = true;
      state.error = null;
    });
    builder.addCase(generalExtraActions.getOrderShops.rejected, (state, {payload}) => {
      state.orderShopsLoading = false;
      state.error = payload;
    });
    builder.addMatcher(
      isAnyOf(
        generalExtraActions.getAreas.pending,
        generalExtraActions.getShops.pending,
        generalExtraActions.createCustomerOrder.pending,
        generalExtraActions.getCustomerReviews.pending,
        generalExtraActions.createCustomerReview.pending,
      ),
      (state, {payload}) => {
        state.loading = true;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        generalExtraActions.getAreas.rejected,
        generalExtraActions.getCategories.rejected,
        generalExtraActions.getShops.rejected,
        generalExtraActions.getShopsFromStart.rejected,
        generalExtraActions.createCustomerOrder.rejected,
        generalExtraActions.getCustomerReviews.rejected,
        generalExtraActions.createCustomerReview.rejected,
      ),
      (state, {payload}) => {
        state.loading = false;
        state.error = payload;
      }
    );
  },
});

export const {actions: generalActions, reducer} = authSlice;
export default reducer;
