import React from 'react';
import { Form, Modal } from "react-bootstrap";
import { labels, schemas } from "common";
import { Formik } from "formik";
import { LoaderBtn } from "../../components";

function LoginModal({ show, setShow, onSubmit, loading }) {


  return (
    <Modal className='loginModal' show={show} fullscreen="md-down" onHide={() => setShow(false)} centered>
      <Modal.Header closeButton>
        <div className='smallTitle'><span>Sign-in using <span className='waColor'>WhatsApp</span></span></div>
        <Modal.Title>Activate Your Session</Modal.Title>
        <p>Enter your "WhatsApp registered" number to activate your session.</p>
      </Modal.Header>

      <Formik
        validationSchema={schemas.login}
        onSubmit={onSubmit}
        initialValues={{
          phoneNumber: '',
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors, isValid }) => (
          <Form id="form" onSubmit={handleSubmit} noValidate>
            <Modal.Body> 
              <div className='formFields'>
                <Form.Label for="phoneNumber">
                Enter WhatsApp number <span style={{ color: "red" }}>*</span>
                </Form.Label>
                <div className="input-group has-validation">
                  <span className="input-group-text" id="inputGroupPrepend">+92</span>
                  <Form.Control id="phoneNumber" name="phoneNumber" type="tel" placeholder="3xxxxxxxxx"
                    value={values.phoneNumber} isInvalid={!!errors.phoneNumber} onChange={handleChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phoneNumber}
                  </Form.Control.Feedback>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <LoaderBtn loading={loading} title={labels.PROCEED} disabled={!isValid || loading} />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
}

export default LoginModal;

