import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, OverlayTrigger, Spinner } from "react-bootstrap";
import { LoaderBtn, ShopMenus, ShopProducts, TranscriptionLoader, TranscriptionRetry } from "components";
import { ImgCancel } from "assets/images";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { useFormik } from "formik";
import { labels, schemas, transUtils, utils } from "common";
import { useDispatch, useSelector } from "react-redux";
import { generalExtraActions } from "reduxStore/actions";
import { generalActions } from "reduxStore/reducers/generalSlice";
import { useParams } from "react-router-dom";
import { TypeAnimation } from "react-type-animation";

function OrderStep2({
  shopParam,
  areaParam,
  category,
  setCategory,
  shops,
  categories,
  customerAddress,
  handleFormSubmit,
  loading
}) {
  const dispatch = useDispatch();
  const {currentShop, currentShopLoading} = useSelector((state) => state.general);
  const recorderRef = useRef(null);
  const recorderControls = useAudioRecorder();
  const [isShopClosed, setIsShopClosed] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [transcriptionLoader, setTranscriptionLoader] = useState(false);
  const [rawTranscription, setRawTranscription] = useState(null);
  const [transcriptionInProgress, setTranscriptionInProgress] = useState(false);
  const [processedTranscription, setProcessedTranscription] = useState(null);
  const [transFailed, setTransFailed] = useState(false);
  const {token} = useParams();

  const handleValidation = (values) => {
    if (!values.order_text) {
      return {
        order_text: 'Please provide order text.'
      }
    }
  }

  const formik = useFormik({
    initialValues: {
      shop: shopParam || 0,
      order_text: '',
      order_audio: null,
      transport: null,
    },
    onSubmit: handleFormSubmit,
    validationSchema: schemas.order,
    validate: handleValidation
  });

  const handleProductChange = (e) => {
    const product_name = e.target.value;
    if (product_name && product_name !== labels.SELECT) {
      let text = formik.values.order_text;
      text += text ? `\n${product_name}` : product_name;
      formik.setFieldValue('order_text', text);
    }
  };
  const handleRecordingComplete = (blob) => {
    formik.setFieldValue('order_audio', blob);
  };

  const handleAudioDelete = () => {
    formik.setFieldValue('order_audio', null);
  };

  const handleCategoryChange = (category) => {
    dispatch(generalActions.resetCurrentShop());
    setCategory(category);
    formik.setFieldValue('shop', 0);
  }

  const handleShopChange = async (event) => {
    const selectedShop = shops.find(s => s.id == event.target.value)
    setCategory(selectedShop.category);
    formik.handleChange(event);
    if(!processedTranscription) {
      formik.setFieldValue('order_text', '');
    }
    formik.setFieldValue('transport', null);
    if(event.target.value != 0) {
      await formik.setFieldTouched('shop', true);
      dispatch(generalExtraActions.getShopDetail(event.target.value));
      formik.validateField('shop');
    }
  }

  const renderTransportOption = (option, isOffered) => {
    const id = option === "Pickup" ? 1 : 2;
    return (
      <OverlayTrigger
        placement="top"
        overlay={utils.renderTooltip(option)}
        trigger={!isOffered ? "hover" : []}
      >
        <div>
          <Form.Check
            id={`transport-${id}`}
            type="radio"
            name="transport"
            value={option.toUpperCase()}
            label={option}
            onChange={formik.handleChange}
            className={!isOffered ? "disabled" : ""}
          />
        </div>
      </OverlayTrigger>
    );
  };

  useEffect(() => {
    if (recorderRef.current) {
      const ele = recorderRef.current.querySelector('[data-testid="ar_cancel"]');
      ele?.setAttribute('title', 'Stop recording');
    }
  }, []);

  useEffect(()=>{
    recorderControls.isRecording && setRawTranscription(null);
  },[recorderControls])

  useEffect(() => {
    shopParam && !currentShopLoading && dispatch(generalExtraActions.getShopDetail(shopParam));
  }, [dispatch]);

  useEffect(()=>{
    if (currentShop) {
      setIsShopClosed(utils.isShopClosed(currentShop?.schedules));
    }
  },[currentShop])

  useEffect(() => {
    if (formik.values.order_audio) {
      setAudioBlob(URL.createObjectURL(formik.values.order_audio));
    }
    transUtils.processTranscription(
      formik, setTranscriptionInProgress, token, setTranscriptionLoader,
      setRawTranscription, setProcessedTranscription, setTransFailed,
    );
  }, [formik.values.order_audio]);

  return (
    <div id="step-2" className="content">
      <div className="text-danger">
        {areaParam && areaParam != customerAddress?.area &&
          <span id="error-area-match">Selected shop doesn't exist in your area, please choose another shop.</span>}
        {isShopClosed && 
          <span>Selected shop is closed right now, please choose another shop.</span>
        }
      </div>
      <div className="info">
        <p>
          Place an order through us is as simple as 1, 2, 3:
        </p>
      </div>
      <Form className="order-form" onSubmit={formik.handleSubmit}>
        <div className="row">
          <h3 className="heading">Step 1: Choose your store</h3>
          <div className="input-wrapper">
            <p className="mt0">Store Category</p>
            <Form.Select id="category" name="category" value={category}
                         onChange={(e) => handleCategoryChange(e.target.value)}>
              <option id="defaultCategory" value="0" selected>{labels.SELECT}</option>
              {categories?.map((category, index) => {
                return <option key={index} id="defaultCategory" value={category.id}>{category.name}</option>
              })}
            </Form.Select>
          </div>
          <div className="input-wrapper">
            <p>Store, Restaurant or Other Business</p>
            <Form.Select id="shop" name="shop" value={formik.values.shop} onChange={handleShopChange}
              isInvalid={formik.touched.shop && !!formik.errors.shop}
            >
              <option id="defaultShop" value="0" selected>{labels.SELECT}</option>
              {shops.map((shop, index) => {
                if (!category || category == 0 || (category && category == shop.category)) {
                  return <option key={index} id="defaultShop" value={shop.id}>{shop.name}</option>
                }
              })}
            </Form.Select>
          </div>
          <div className="text-danger">
            {formik.touched.shop && formik.errors.shop}
          </div>
        </div>
        {currentShopLoading ? <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            aria-hidden="true"
          />
          : <>
            <ShopMenus menu={currentShop?.menu_items}/>
            <ShopProducts products={currentShop?.products} handleProductChange={handleProductChange} source={"Whatsapp"}/>
          </>
        }
        <div className="row">
          <h3 className="heading">Step 2: Type or dictate your order</h3>
          <div className="input-wrapper">
            <Form.Control as="textarea" name="order_text" id="order-text" placeholder="Your order..."
              value={formik.values.order_text} onChange={formik.handleChange}
              isInvalid={formik.touched.order_text && !!formik.errors.order_text}
            >
            </Form.Control>

            {rawTranscription && processedTranscription == null ? (
              <>
                <TypeAnimation
                  sequence={[rawTranscription]}
                  wrapper="span"
                  className="transWrap"
                  speed={50}
                  repeat={0}
                />
              </>
            ) : processedTranscription ? (
              <span className="transWrap">{rawTranscription}</span>
            ) : null}
            <div className="text-danger">
              {formik.touched.order_text && formik.errors.order_text}
            </div>
            <div className="audio-section" id="audio-section">
              {formik.values.order_audio &&
                <>
                  <audio id="audio-play"
                         src={audioBlob}
                         className="audio-play"
                         controls></audio>
                  <Button id="audio-delete" className="audio-delete" onClick={handleAudioDelete}>
                    <img src={ImgCancel} alt="x"/>
                  </Button>
                  <TranscriptionRetry formik={formik} setInProgress={setTranscriptionInProgress} token={token}
                    setLoader={setTranscriptionLoader} setRaw={setRawTranscription} setProcessed={setProcessedTranscription} 
                    failed={transFailed} setFailed={setTransFailed}/>
                </>
              }
            </div>
            <div ref={recorderRef} className="btn-section">
            <TranscriptionLoader isLoading={transcriptionInProgress} text={transcriptionLoader} />
            <div className={transcriptionInProgress && 'disable'}>
              <AudioRecorder
                recorderControls={recorderControls}
                onRecordingComplete={handleRecordingComplete}
                downloadOnSavePress={false}
                audioTrackConstraints={{
                  noiseSuppression: true,
                  echoCancellation: true,
                }}
                downloadFileExtension="mp3"
              />
            </div>     
            </div>
          </div>
        </div>
        <div className="row">
          <h3 className="heading">Step 3: Choose Pickup or Delivery</h3>
          <div className="input-wrapper">
            <div className="radio-group">
              {!currentShop?.is_pickup_offered && !currentShop?.is_delivery_offered
                && <div className="text-black">
                  Please select the Shop to see transport options
                </div>
              }
              {currentShop && (
                <>
                  {renderTransportOption("Pickup", currentShop.is_pickup_offered)}
                  {renderTransportOption("Delivery", currentShop.is_delivery_offered)}
                </>
              )}
            </div>
          </div>
          <div className="text-danger">
            {formik.touched.transport && formik.errors.transport}
          </div>
        </div>
        <div className="info">
          <p>
            After you press submit, we will text a confirmation link to your phone.
            Please check that we have translated your order correctly and confirm it
            by clicking on the link.
          </p>
        </div>
        <div className="row">
          <LoaderBtn
            id="submit-btn"
            disabled={!formik.isValid || isShopClosed || transcriptionInProgress}
            title={labels.SUBMIT}
            loading={loading}
          />
        </div>
      </Form>
    </div>
  );
}

export default OrderStep2;

