import React, { useEffect, useRef } from "react";
import "pages/home/_home.scss";
import { hooks, labels, utils } from "common";
import { useDispatch, useSelector } from "react-redux";
import { generalExtraActions } from "../../reduxStore/actions";
import { HowItWorks, ExpandingNetwork, Benefits, HeroBanner, ChatBot } from "components";
import { homePageData } from "assets/data";
import Areas from "components/home/areas/Areas";
import { useLocation } from "react-router";

function Home() {
  const dispatch = useDispatch();
  const areasRef = useRef();
  const location = useLocation();
  const howItWorksRef = useRef();
  const { areas, loading } = useSelector((state) => state.general);
  const scrollToAreas = () => areasRef.current.scrollIntoView();
  const scrolltoHowItWorks = () => howItWorksRef.current.scrollIntoView();

  useEffect(() => {
    document.body.classList.add("home");
    return () => {
      document.body.classList.remove("home");
    };
  }, []);

  useEffect(() => {
    if (location.hash === "#areas") {
      const areasSection = document.getElementById("areas");
      if (areasSection) {
        areasSection.scrollIntoView({ behavior: "instant" });
      }
    }
  }, [location]);

  useEffect(() => {
    dispatch(generalExtraActions.getAreas({ isactive: 1 }));
  }, [dispatch]);

  hooks.usePageTitle(labels.HOME);

  if(location.hash !== "#areas") {
    hooks.useScrollToTop();
  }

  return (
    <div className="home">
      {/* Hero Banner */}
      <section className="heroBanner">
        <HeroBanner scrollToAreas={scrollToAreas} />
      </section>

      {/* All Serving Areas */}
      <section ref={areasRef} className="areasWrap" id="areas">
        <Areas loading={loading} areas={areas} />
      </section>

      {/* How It Workes Section */}
      <section ref={howItWorksRef} className="howItWorks" id="howItWorks">
        <HowItWorks steps={homePageData.howItWorks.steps} />
      </section>

      {/* WhatsApp Bot Section */}
      <section className="whatsappSection">
        <ChatBot />
      </section>

      {/* Stats Section */}
      <section className="statsSection">
        <ExpandingNetwork />
      </section>

      {/* Benefits Section */}
      <section className="allBenefits">
        <Benefits
          benefits={homePageData.benefits}
          scrollToAreas={scrollToAreas}
        />
      </section>

      {/* Footer Section (Specific to Home Page only) */}
      <footer>
        <div className="container">
          <div className="row">
            <div className="holder">
              <span className="copyRight">© Fatafat 2024</span>
              <ul className="links">
                <li>
                  <a onClick={utils.scrollWindowToTop}>About Us</a>
                </li>
                <li>
                  <a onClick={scrollToAreas}>Explore Your Area</a>
                </li>
                <li>
                  <a onClick={scrolltoHowItWorks}>How it Works</a>
                </li>
                {/* <li><a href="#">Privacy Policy</a></li>
              <li><a href="#">Terms</a></li> */}
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Home;

