import React from 'react';
import { Formik } from "formik";
import { labels, schemas, utils } from "common";
import { Form } from "react-bootstrap";
import { LoaderBtn } from "components";

function OrderStep1({areas, customerDetail, customerAddresses, handleFormSubmit, loading}) {
  const areasMap = utils.toArrayMappingById(areas);

  return (
    <div id="step-1" className="content">
      <div className="info">
        <p>
          Hi <span id="customer-phone">{customerDetail?.phone_number || "Unknown Customer"}</span>, thank you for
          using fatafat to place your order.
        </p>
      </div>
      <Formik
        validationSchema={schemas.customerAddress}
        onSubmit={handleFormSubmit}
        initialValues={{
          existing_address: null,
          address: '',
          area: 0,
        }}
      >
        {({handleSubmit, handleChange, values, touched, errors}) => (
          <Form className="order-form" onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="error">
                <span id="error-existing-address"></span>
              </div>
              <h3 className="heading">Choose Address</h3>
              <div className="input-wrapper">
                <div id="address-select" className="radio-group radio-group-col">
                  <Form.Check
                    className={customerAddresses.length > 0 && "add-address"}
                    key={0}
                    id="address-0"
                    type="radio"
                    value={0}
                    name="existing_address"
                    label="Add New Address"
                    isInvalid={!!errors.existing_address}
                    onChange={handleChange}
                  />
                  {customerAddresses?.map((ca, index) => {
                    return <Form.Check
                      key={index + 1}
                      id={`address=-${index + 1}`}
                      type="radio"
                      value={ca.id}
                      name="existing_address"
                      label={`${ca.title}${areasMap[ca.area]?.area ? ", " + areasMap[ca.area].area : ""}`}
                      isInvalid={!!errors.existing_address}
                      onChange={handleChange}
                    />
                  })}
                </div>
              </div>
              <div className="text-danger">
                {errors.existing_address}
              </div>
            </div>
            {values.existing_address == 0 &&
              <div id="address-section">
                <div className="row">
                  <h3 className="heading">Add new Address</h3>
                  <div className="input-wrapper">
                    <Form.Control name="address" id="address" as="textarea" rows={3}
                                  placeholder="your address e.g.: House # 1, Street # 2, ..."
                                  value={values.address}
                                  isInvalid={touched.address && !!errors.address}
                                  onChange={handleChange}
                    />
                  </div>
                  <div className="text-danger">
                    {touched.address && errors.address}
                  </div>
                </div>
                <div className="row">
                  <h3 className="heading">Choose new Address Area</h3>
                  <div className="input-wrapper">
                    <Form.Select id="area" name="area"
                                 value={values.area}
                                 isInvalid={touched.area && !!errors.area}
                                 onChange={handleChange}
                    >
                      <option value={0} selected>{labels.SELECT}</option>
                      {areas?.map((area, index) => {
                        return <option key={index} value={area.id} selected>{area.area}</option>
                      })}
                    </Form.Select>
                  </div>
                  <div className="text-danger">
                    {touched.area && errors.area}
                  </div>
                </div>
              </div>
            }
            <div className="row">
              <LoaderBtn id="next-btn" title={labels.NEXT} loading={loading}/>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default OrderStep1;