import { useEffect, useState } from 'react';
import { labels, local } from 'common';

export const hooks = {
  useToken: () => {
    const [accessToken, setTokens] = useState(local.getAccessToken());

    const saveTokens = (tokens) => {
      if (tokens) {
        local.storeTokens(tokens);
        setTokens(tokens.access);
      }
    };

    return {
      setTokens: saveTokens,
      accessToken,
    };
  },
  usePageTitle: (title) => {
    useEffect(() => {
      document.title = `${labels.APP} - ${title}`;
    }, [title]);
    return null;
  },
  useScrollToTop: () => {
    useEffect(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
    }, []);
  },
  useCloseModalOnBack: (isOpen, setIsOpen) => {
    useEffect(() => {
      if (isOpen) {
        window.history.pushState(null, '', window.location.href);
        const handlePopState = () => {
          window.onpopstate = null;
          setIsOpen(false);
        };

        window.onpopstate = handlePopState;

        return () => {
          window.onpopstate = null;
        };
      }
    }, [isOpen, setIsOpen]);
  },
};
