import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import StarRatings from "react-star-ratings";
import { clauses, globals, labels, schemas } from "common";
import { LoaderBtn } from "components";
import "./_reviewModal.scss";

function ReviewModal({
  shopName,
  show,
  loading,
  customerReview,
  setShowReviewModal,
  onSubmit,
  handleEditReview,
}) {
  const customerHasReview = customerReview?.review || customerReview?.rating;
  const [error, setError] = useState(false);
  const [count, setCount] = useState(0);

  const handleCancelButton = (e) => {
    e.preventDefault();
    setShowReviewModal(false);
    setError(false);
  };

  const handleReviewChange = (e, handleChange) => {
    const value = e.target.value;
    if (value.length <= globals.REVIEW_MAX_LENGTH) {
      handleChange(e);
      setCount(value.length);
    }
  };

  const handleRatingChange = (newRating, setFieldValue) => {
    setFieldValue("rating", newRating);
    if (newRating > 0) {
      error && setError(false);
    }
  };

  useEffect(() => {
    customerReview?.review && setCount(customerReview.review.length);
  }, [customerReview]);

  return (
    <Modal
      show={show}
      fullscreen="md-down"
      centered
      onHide={() => {
        setShowReviewModal(false);
        setError(false);
      }}
    >
      <Modal.Header closeButton>
        <Modal.Title as="h1">
          {customerHasReview ? "Edit Review" : "Share Your Experience"}
        </Modal.Title>
        <Modal.Title as="h2">
          <i>Help others by sharing your feedback about {shopName}.</i>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{marginBottom: '0'}}>
        <Formik
          validateOnChange={false}
          validationSchema={schemas.write_review}
          onSubmit={customerHasReview ? handleEditReview : onSubmit}
          initialValues={{
            review: customerReview?.review ? customerReview?.review : "",
            rating: customerReview?.rating ? customerReview?.rating : 0,
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            validateField,
            dirty,
            values,
            touched,
            errors,
          }) => (
            <Form id="form" onSubmit={handleSubmit} style={{ width: "100%" }}>
              <div className="ratingWrap">
                <Form.Label>Rate Your Experience:</Form.Label>
                <StarRatings
                  name="rating"
                  starSpacing="2px"
                  starDimension="24px"
                  rating={values.rating}
                  numberOfStars={5}
                  starRatedColor="#6C5CE7CC"
                  starHoverColor="#6C5CE7CC"
                  changeRating={(newRating) => {
                    handleRatingChange(newRating, setFieldValue);
                  }}
                />
                <div className="text-danger">{error && errors.rating}</div>
              </div>

              <Form.Control
                name="review"
                placeholder={clauses.WRITE_REVIEW}
                as="textarea"
                style={{minHeight: "150px"}}
                value={values.review}
                onChange={(e) => {
                  handleReviewChange(e, handleChange);
                }}
                isInvalid={touched.review && !!errors.review}
              />
              <div className="wCount">
              <span
                className={count == globals.REVIEW_MAX_LENGTH && "text-danger"}
              >
                {count}/{globals.REVIEW_MAX_LENGTH}
              </span>
              </div>
              
              <Modal.Footer className="mt-3">
                <LoaderBtn
                  loading={loading}
                  disabled={loading || error || (customerHasReview && !dirty)}
                  title={customerHasReview ? labels.UPDATE : labels.SUBMIT}
                  onClick={() => {
                    if (values.rating == 0) {
                      validateField("rating");
                      setError(true);
                    }
                  }}
                />
                <button
                  disabled={loading}
                  onClick={handleCancelButton}
                  className="btn btn-secondary cancel"
                  aria-label="Close"
                >
                  Cancel
                </button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default ReviewModal;

