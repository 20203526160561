import React, { useEffect, useState } from "react";
import "pages/vendorDetail/_vendorDetail.scss";
import moment from "moment";
import { ImgCustomer } from "assets/images";
import { ShowMoreText } from "components";
import StarRatings from "react-star-ratings";

function Review({ review, customerReview }) {
  const [edit, setEdit] = useState(false);
  const [displayDate, setDisplayDate] = useState(customerReview?.created);
  const isCustomerReview = customerReview && customerReview?.id == review?.id;
  const isEdited = review?.created !== review?.modified;

  useEffect(() => {
    if (customerReview?.created != customerReview?.modified) {
      setEdit(true);
      setDisplayDate(customerReview?.modified);
    }
  }, [customerReview]);

  return (
    <li>
      <div className="head">
        <div>
          <div className="avatar">
            <img src={ImgCustomer} alt="reviwer" />
          </div>
          <div className="nickName">{review?.phone_number}</div>
          <StarRatings
            starSpacing="2px"
            starDimension="14px"
            rating={isCustomerReview ? customerReview?.rating : review?.rating}
            starRatedColor="#6C5CE7CC"
            numberOfStars={5}
            name="rating"
          />
          <span className="rating">
            {isCustomerReview ? customerReview?.rating : review?.rating}
          </span>
        </div>
        <div className="dateArea">
          {isEdited || (isCustomerReview && edit)
            ? "Edited " +
              ((isCustomerReview)
                ? moment(displayDate).fromNow()
                : moment(review?.modified).fromNow())
            : moment(review?.created).fromNow()}
        </div>
      </div>
      <div className="reviewText">
        <ShowMoreText
          text={isCustomerReview ? customerReview?.review : review?.review}
        />
      </div>
    </li>
  );
}

export default Review;

